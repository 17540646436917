/* eslint-disable import/extensions */
<template>
  <el-dialog
    v-if="dialogVisible"
    :visible.sync="dialogVisible"
    :before-close="cancel"
    :close-on-click-modal="false"
    append-to-body
    width="880px"
    style="padding: 10px"
    class="task-manger-step-three1-dialog"
  >
    <div
      slot="title"
      class="dialog-header"
    >
      <div class="dialog-index">
        3
      </div>
      <span class="dialog-title">{{ title }}</span>
    </div>

    <div
      v-if="dialogVisible"
      v-loading="loading"
    >
      <div class="dialog-body">
        <div class="template-three-switch">
          <div
            class="template-three-switch-item"
            @click="handleSwitchScenarios"
          >
            <div
              v-if="switchType === 'Scenarios'"
              class="template-three-switch-item__checked"
            />
            <div
              v-else
              class="template-three-switch-item__uncheck"
            />
            <span class="template-three-switch-item__text">{{$t('taskManagerInfo.Scenarios')}}</span>
          </div>
          <div
            class="template-three-switch-item"
            @click="handleSwitchESLType"
          >
            <div
              v-if="switchType === 'ESLType'"
              class="template-three-switch-item__checked"
            />
            <div
              v-else
              class="template-three-switch-item__uncheck"
            />
            <span class="template-three-switch-item__text">{{$t('taskManagerInfo.ESL Type')}}</span>
          </div>
        </div>
        <templateEslType
          v-if="switchType === 'ESLType'"
          ref="templateEslTypeRef"
          :form="form"
          style="margin-top: 20px"
        />
        <templateScenarios
          v-if="switchType === 'Scenarios'"
          ref="templateScenariosRef"
          :form="form"
          style="margin-top: 20px"
        />
      </div>
      <div
        slot="footer"
        class="dialog-footer__four"
      >
        <el-button
          class="button-next gray-button"
          @click="handlePrior"
          size="small"
        >
          {{$t('taskManagerInfo.Prior')}}
        </el-button>
        <el-button
          class="button-next green-button"
          @click="handleNext"
          size="small"
        >
          {{ form.executionMode === 1 ? `${$t('taskManagerInfo.Next')}` : `${$t('taskManagerInfo.Complete')}` }}
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import modelUi from '@/views/taskManagement/taskManger/utils/modelUi.js'

import templateEslType from '@/views/taskManagement/taskManger/component/templateEslType.vue'
import templateScenarios from '@/views/taskManagement/taskManger/component/templateScenarios.vue'

import { createSchedule, updateSchedule } from '@/libs/api/taskManger.js'

import { checkTriggerConditionVerify } from '@/views/taskManagement/taskManger/utils/taskTriggerCondition'

import {
  parseResp,
  parseError,
} from '@/views/taskManagement/taskManger/utils/requestUtil.js'

export default {
  components: {
    templateEslType,
    templateScenarios,
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      title: '',
      srcForm: {},
      form: {},
      switchType: 'Scenarios',
    }
  },
  methods: {
    openDialog(row) {
      this.srcForm = JSON.parse(JSON.stringify(row))
      this.form = row
      this.dialogVisible = true

      if (row.taskType === 1207) {
        this.title =  this.$t('taskManagerInfo.Select objects you want to apply (Switch Template)')
      }
      if (row.taskType === 1206) {
        this.title =  this.$t('taskManagerInfo.Select objects you want to apply (Switch Layout)')
      }
      if (row.taskType === 1203) {
        this.title =  this.$t('taskManagerInfo.Select objects you want to apply (LED)')
      }
      if (row.taskType === 1204) {
        this.title =  this.$t('taskManagerInfo.Select objects you want to apply (Switch Page)')
      }
      if (row.taskType === 1205) {
        this.title =  this.$t('taskManagerInfo.Select objects you want to apply (NFC)')
      }

      if (this.form.eslType) {
        this.switchType = 'ESLType'
      } else {
        this.switchType = 'Scenarios'
      }
    },
    cancel() {
      this.dialogVisible = false
    },
    handleSwitchScenarios() {
      this.switchType = 'Scenarios'
    },
    handleSwitchESLType() {
      this.switchType = 'ESLType'
    },

    handlePrior() {
      this.$emit('stepTwo', this.srcForm)
      this.dialogVisible = false
    },

    handleNext() {
      let tagTypeList = []
      if (this.$refs.templateEslTypeRef) {
        tagTypeList = this.$refs.templateEslTypeRef.tagTypeList
      }

      if (this.switchType === 'ESLType') {
        let eslType = ''
        if (tagTypeList && tagTypeList.length > 0) {
          tagTypeList.forEach(tagTypeItem => {
            if (tagTypeItem.checked) {
              // eslint-disable-next-line prefer-destructuring
              const tagTypeCodeId = tagTypeItem.tagTypeCodeId
              if (eslType) {
                // eslint-disable-next-line prefer-template
                eslType += ',' + tagTypeCodeId
              } else {
                eslType = tagTypeCodeId
              }
            }
          })
        }
        if (!eslType) {
          this.$message.error(this.$t('taskManagerInfo.ESLType is Null'))
          // modelUi.msgError(this.$t('taskManagerInfo.ESLType is Null'))
          return
        }
        this.form.eslType = eslType
        this.form.taskTriggerCondition = ''
      }

      if (this.switchType === 'Scenarios') {
        let taskTempCondition = {}
        if (this.$refs.templateScenariosRef) {
          taskTempCondition = this.$refs.templateScenariosRef.taskTriggerCondition
        }
        let taskTriggerCondition = {}
        const child1List = []
        const { children } = taskTempCondition || []
        if (children && children.length > 0) {
          children.forEach(childrenItem => {
            const { itemType } = childrenItem
            if (itemType === 'child') {
              child1List.push({
                fieldName: childrenItem.fieldName,
                symbol: childrenItem.symbol,
                isSystemField: childrenItem.isSystemField,
                fieldType: childrenItem.fieldType,
                fieldValue: childrenItem.fieldValue,
              })
            } else if (itemType === 'group') {
              const childCondition = childrenItem.condition
              if (childCondition === 'or') {
                const orList = []
                const child2List = childrenItem.children || []
                child2List.forEach(child2Item => {
                  orList.push({
                    fieldName: child2Item.fieldName,
                    symbol: child2Item.symbol,
                    isSystemField: child2Item.isSystemField,
                    fieldType: child2Item.fieldType,
                    fieldValue: child2Item.fieldValue,
                  })
                })
                child1List.push({
                  or: orList,
                })
              } else if (childCondition === 'and') {
                const andList = []
                const child2List = childrenItem.children || []
                child2List.forEach(child2Item => {
                  andList.push({
                    fieldName: child2Item.fieldName,
                    symbol: child2Item.symbol,
                    isSystemField: child2Item.isSystemField,
                    fieldType: child2Item.fieldType,
                    fieldValue: child2Item.fieldValue,
                  })
                })
                child1List.push({
                  and: andList,
                })
              }
            }
          })
          if (taskTempCondition.condition === 'or') {
            taskTriggerCondition.or = child1List
          }
          if (taskTempCondition.condition === 'and') {
            taskTriggerCondition.and = child1List
          }
        } else {
          taskTriggerCondition = null
        }
        const checkTriggerConditionResult = checkTriggerConditionVerify(taskTriggerCondition)
        if (checkTriggerConditionResult) {
          this.$message.error(checkTriggerConditionResult)
          // modelUi.msgError(checkTriggerConditionResult)
          return
        }
        this.form.taskTriggerCondition = JSON.stringify(taskTriggerCondition)
        this.form.eslType = ''
      }

      this.srcForm = JSON.parse(JSON.stringify(this.form))

      if (this.form.executionMode === 1) {
        // 进入第四步
        this.$emit('stepFour', this.form)
        this.dialogVisible = false
      } else {
        // 提交
        // eslint-disable-next-line no-lonely-if
        if (this.form.taskScheduleId) {
          this.loading = true
          updateSchedule(this.form)
            .then(res => {
              this.loading = false
              const result = parseResp(res)
              if (result.success) {
                this.$emit('submit')
                this.dialogVisible = false
              } else {
                // modelUi.msgError(result.message)
                this.$message.error(result.message)
              }
            })
            .catch(err => {
              this.loading = false
              modelUi.msgError(parseError(err))
            })
        } else {
          this.loading = true
          createSchedule(this.form)
            .then(res => {
              this.loading = false
              const result = parseResp(res)
              if (result.success) {
                this.$emit('submit')
                this.dialogVisible = false
              } else {
                modelUi.msgError(result.message)
              }
            })
            .catch(err => {
              this.loading = false
              modelUi.msgError(parseError(err))
            })
        }
      }
    },
  },
}
</script>

<style lang="scss" scope>
.task-manger-step-three1-dialog {
  .dialog-header {
    display: flex;
    align-items: center;
  }
  .dialog-index {
    width: 26px;
    height: 26px;
    line-height: 26px;
    font-size: 14px;
    background: linear-gradient(199deg, #29f19c 0%, #14cad5 100%);
    border-radius: 0px 20px 20px 20px;
    text-align: center;
    font-family: Impact;
    font-weight: 400;
    color: #ffffff;
  }

  .dialog-title {
    margin-left: 10px;
    font-size: 14px;
    font-family: Verdana;
    font-weight: 400;
    color: #35b871;
  }

  .dialog-body {
    display: flex;
    flex-direction: column;
  }

  .el-dialog__header {
    background-color: #E9F9EF;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom: #D3F4E1;
  }

  .el-dialog__body {
    padding: 30px 20px !important;
  }

  .dialog-footer__four {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }

  .template-three-switch {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .template-three-switch-item {
    display: flex;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
    cursor: pointer;
  }

  .template-three-switch-item__checked {
    width: 16px;
    height: 16px;
    background: #ffffff;
    box-shadow: 0px 4px 5px 0px rgba(52, 97, 255, 0.18);
    border-radius: 13px;
    border: 5px solid #3461ff;
  }

  .template-three-switch-item__uncheck {
    width: 16px;
    height: 16px;
    background: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 13px;
  }

  .template-three-switch-item__text {
    color: #555555;
    font-size: 16px;
    margin-left: 10px;
  }

  .button-next {
    // border: 0px;
    width: 110px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
</style>

import router from "../axios";
// import api from "@/config/index"
import qs from 'qs'

import config from "../../config/index"
const { baseUrl } = config;

// 新增接口 --zjp
// 场景配置---场景列表
function scenlistsect(data) {
  return router({
    url: baseUrl+"scenario/v1/getScenarioList",
    data:data,
    method: "POST",
  });
}
// 场景列表-简化版
function getScenarioSimplifyList(data) {
  return router({
    url: baseUrl+"scenario/v1/getScenarioSimplifyList",
    data:data,
    method: "POST",
  });
}
function addscenlis(data) {
  //添加场景
  return router({
    url: baseUrl+"scenario/v1/createScenario",
    data:data,
    method: "POST",
  });
}

// 场景配置---场景修改
function scenlisupdate(data) {
  return router({
    url: baseUrl+"scenario/v1/updateScenario",
    data:data,
    method: "POST",
  });
}
//场景配置---分组新增
function createScenarioGroup(data) {
  return router({
    url: baseUrl+"scenario/v1/createScenarioGroup",
    data:data,
    method: "POST",
  });
}
//场景配置---分组修改
function updateScenarioGroup(data) {
  return router({
    url: baseUrl+"scenario/v1/updateScenarioGroup",
    data:data,
    method: "POST",
  });
}
//场景配置---分组删除
function deleteScenarioGroup(data) {
  return router({
    url: baseUrl+"scenario/v1/deleteScenarioGroup",
    data: data,
    method: "POST",
  });
}
// 场景配置---字段添加
function createScenarioField(data) {
  return router({
    url: baseUrl+"scenario/v1/createScenarioField",
    data:data,
    method: "POST",
  });
}
// 场景配置---字段修改
function updateScenarioField(data) {
  return router({
    url: baseUrl+"scenario/v1/updateScenarioField",
    data:data,
    method: "POST",
  });
}
//场景配置---字段删除
function deleteScenarioField(data) {
  return router({
    url: baseUrl+"scenario/v1/deleteScenarioField",
    data: data,
    method: "POST",
  });
}

// 场景配置--获取素材
function materiallist(data) {
  return router({
    url: baseUrl+"image/v1/getList",
    data:data,
    method: "POST",
  });
}

// 删除场景
function scenlisdel(data) {
  return router({
    url: baseUrl+"scenario/v1/deleteScenario",
    data:data,
    method: "POST",
  });
}

// 获取下级商户
function agentMerchantList(data) {
  return router({
    url: baseUrl+"merchant/v1/agentMerchantList",
    data:data,
    method: "POST",
  });
}

// 场景分享
function scenarioShare(data) {
  return router({
    url: baseUrl+"scenario/v1/share",
    data:data,
    method: "POST",
  });
}
// 场景字段排序
function soreScenarioField(data) {
  return router({
    url: baseUrl+"scenario/v1/soreScenarioField",
    data:data,
    method: "POST",
  });
}


export {
  scenlistsect,
  getScenarioSimplifyList,
  addscenlis,
  scenlisupdate,
  scenlisdel,
  createScenarioGroup,
  updateScenarioGroup,
  deleteScenarioGroup,
  createScenarioField,
  updateScenarioField,
  deleteScenarioField,
  materiallist,
  agentMerchantList,
  scenarioShare,
  soreScenarioField
}
<template>
  <div class="template-scenarios">
    <div
      v-loading="loading"
      class="template-scenarios-group"
    >
      <div
        class="template-scenarios-group-children-item__group"
        style="margin-left: 0px"
      >
        <el-select
          v-model="taskTriggerCondition.condition"
          :placeholder="$t('taskManagerInfo.Please Select')"
          class="template-scenarios-group-children-item__group__select"
          @change="conditionTitleChange($event, taskTriggerCondition)"
          size="small"
        >
          <el-option
            v-for="dict in conditionTitleList"
            :key="dict.dictValue"
            :label="dict.dictName"
            :value="dict.dictValue"
          />
        </el-select>
        <div class="template-scenarios-group-children-item__group__empty" />
        <img
          class="template-scenarios-group-children-item__group__add"
          src="@/assets/img/ic_add.png"
          @click="handleAddChild"
        >
        <img
          class="template-scenarios-group-children-item__group__tree"
          src="@/assets/img/ic_tree.png"
          @click="handleAddGroup"
        >
      </div>
      <div
        v-if="
          taskTriggerCondition.children &&
            taskTriggerCondition.children.length > 0
        "
        class="template-scenarios-group-children"
      >
        <div class="template-scenarios-group-children__line" />
        <div
          v-for="(parentItem, parentIndex) in taskTriggerCondition.children"
          :key="parentItem.itemId"
          class="
            template-scenarios-group-children-item template-scenarios-group
          "
        >
          <div
            v-if="parentItem.itemType === 'child'"
            class="template-scenarios-group-children-item__child"
          >
            <el-select
              v-model="parentItem.fieldName"
              :placeholder="$t('taskManagerInfo.Please Select')"
              class="template-scenarios-group-children-item__child__key"
              clearable
              @change="fieldNameChange($event, parentItem)"
              @visible-change="fieldNameVisibleChange"
              size="small"
            >
              <el-option
                v-for="dict in fieldList"
                :key="dict.fieldId"
                :label="dict.translateTextName"
                :value="dict.columnName"
              />
            </el-select>
            <el-select
              v-model="parentItem.symbol"
              :placeholder="$t('taskManagerInfo.Please Select')"
              class="template-scenarios-group-children-item__child__operator"
              clearable
              size="small"
            >
              <el-option
                v-for="dict in parentItem.symbolList"
                :key="dict.dictValue"
                :label="dict.dictName"
                :value="dict.dictValue"
              />
            </el-select>

            <!-- 第三项---匹配的条件 -->
            <!--来自选择-->
            <el-select
              v-if="parentItem.operateType === 'choose'"
              v-model="parentItem.fieldValue"
              :placeholder="$t('taskManagerInfo.Please Select')"
              class="template-scenarios-group-children-item__child__value"
              clearable
              @change="fieldValueChange"
              @visible-change="fieldValueVisibleChange"
              size="small"
              :disabled="parentItem.symbol ===7 || parentItem.symbol ===8 || parentItem.symbol ===9 || parentItem.symbol ===10"
            >
              <el-option
                v-for="dict in fieldList"
                :key="dict.fieldId"
                :label="dict.translateTextName"
                :value="dict.columnName"
              />
            </el-select>
            <!--来自checkbox  【日期】-->
            <el-select
              v-if="parentItem.operateType === 'checkbox'"
              v-model="parentItem.fieldValue"
              :placeholder="$t('taskManagerInfo.Please Select')"
              class="template-scenarios-group-children-item__child__value"
              clearable
              size="small"
            >
              <el-option
                v-for="dict in checkBoxList"
                :key="dict.fieldId"
                :label="dict.fieldKey"
                :value="dict.fieldId"
              />
            </el-select>
            <!--来自写入 【文本】-->
            <el-input
              v-if="parentItem.operateType === 'write' && fieldTypeIsText(parentItem)"
              v-model="parentItem.fieldValue"
              class="template-scenarios-group-children-item__child__value"
              clearable
              size="small"
              :disabled="parentItem.symbol ===7 || parentItem.symbol ===8 || parentItem.symbol ===9 || parentItem.symbol ===10"
            />
            <!--来自写入 【数字】-->
            <el-input-number
              v-if="parentItem.operateType === 'write' && fieldTypeIsNumber(parentItem)"
              v-model="parentItem.fieldValue"
              class="template-scenarios-group-children-item__child__value"
              clearable
              size="small"
            />
            <!--来自写入  【日期】-->
            <el-date-picker
              v-if="parentItem.operateType === 'write' && fieldTypeIsDate(parentItem)"
              v-model="parentItem.fieldValue"
              type="datetime"
              class="template-scenarios-group-children-item__child__value"
              clearable
              size="small"
              placement="bottom-start"
            />
            <!-- 来自写入 【checkbox】 -->
            <template v-if="parentItem.operateType === 'write' && fieldTypeIsCheckbox(parentItem)">
              <el-select 
              v-model="parentItem.fieldValue" 
              multiple placeholder="" 
              collapse-tags 
              size="small" 
              class="template-scenarios-group-children-item__child__value"
              :disabled="parentItem.symbol ===7 || parentItem.symbol ===8 || parentItem.symbol ===9 || parentItem.symbol ===10"
            >
                <el-option
                  v-for="(item,index ) of parentItem.checkBoxList"
                  :key="index"
                  :label="item.text"
                  :value="item.text">
                </el-option>
              </el-select>
            </template>
            

            <!-- 第四项：切换区域----write、choice 、checkbox  -->
            <div class="template-scenarios-group-children-item__child__switch">
              <div
                class="template-scenarios-group-children-item__child__write"
                :class="
                  parentItem.operateType === 'write'
                    ? 'operate_type_checked'
                    : ''
                "
                @click="handleWrite(parentItem)"
              >
                {{ $t('taskManagerInfo.Write') }}
              </div>
              <div
                v-if="!fieldTypeIsDate(parentItem)"
                class="template-scenarios-group-children-item__child__choose"
                :class="
                  parentItem.operateType === 'choose'
                    ? 'operate_type_checked'
                    : ''
                "
                @click="handleChoose(parentItem)"
              >
                {{ $t('taskManagerInfo.Choice') }}
              </div>
              <div
                v-if="fieldTypeIsDate(parentItem)"
                class="template-scenarios-group-children-item__child__choose2"
                :class="
                  parentItem.operateType === 'choose'
                    ? 'operate_type_checked'
                    : ''
                "
                @click="handleChoose(parentItem)"
              >
                {{ $t('taskManagerInfo.Choice') }}
              </div>
              <div
                v-if="fieldTypeIsDate(parentItem)"
                class="template-scenarios-group-children-item__child__choose"
                :class="
                  parentItem.operateType === 'checkbox'
                    ? 'operate_type_checked'
                    : ''
                "
                @click="handleCheckbox(parentItem)"
              >
                {{ $t('taskManagerInfo.Function') }}
              </div>
            </div>
            <img
              class="template-scenarios-group-children-item__group__del"
              src="@/assets/img/ic_delete.png"
              @click="handleRemoveChild(parentIndex)"
            >
          </div>
          <div
            v-if="parentItem.itemType === 'group'"
            class="template-scenarios-group template-scenarios-group"
          >
            <div class="template-scenarios-group-children-item__group">
              <el-select
                v-model="parentItem.condition"
                :placeholder="$t('taskManagerInfo.Please Select')"
                class="template-scenarios-group-children-item__group__select"
                @change="conditionTitleChange($event, parentItem)"
                size="small"
              >
                <el-option
                  v-for="dict in conditionTitleList"
                  :key="dict.dictValue"
                  :label="dict.dictName"
                  :value="dict.dictValue"
                />
              </el-select>
              <div
                class="template-scenarios-group-children-item__group__empty"
              />
              <img
                class="template-scenarios-group-children-item__group__del"
                src="@/assets/img/ic_delete.png"
                @click="handleRemoveChild(parentIndex)"
              >
              <img
                class="template-scenarios-group-children-item__group__add"
                src="@/assets/img/ic_add.png"
                @click="handleAddChildChild(parentItem)"
              >
            </div>
            <div
              v-if="parentItem.children && parentItem.children.length > 0"
              class="template-scenarios-group-children"
            >
              <div class="template-scenarios-group-children__line" />
              <div
                v-for="(childrenItem, childrenIndex) in parentItem.children"
                :key="childrenItem.itemId"
                class="
                  template-scenarios-group-children-item
                  template-scenarios-group
                "
              >
                <div class="template-scenarios-group-children-item__child">
                  <el-select
                    v-model="childrenItem.fieldName"
                    :placeholder="$t('taskManagerInfo.Please Select')"
                    class="template-scenarios-group-children-item__child__key"
                    @change="fieldNameChange($event, childrenItem)"
                    @visible-change="fieldNameVisibleChange"
                    size="small"
                  >
                    <el-option
                      v-for="dict in fieldList"
                      :key="dict.fieldId"
                      :label="dict.translateTextName"
                      :value="dict.columnName"
                    />
                  </el-select>
                  <el-select
                    v-model="childrenItem.symbol"
                    :placeholder="$t('taskManagerInfo.Please Select')"
                    size="small"
                    class="
                      template-scenarios-group-children-item__child__operator
                    "
                  >
                    <el-option
                      v-for="dict in childrenItem.symbolList"
                      :key="dict.dictValue"
                      :label="dict.dictName"
                      :value="dict.dictValue"
                    />
                  </el-select>
                  <!--来自选择-->
                  <el-select
                    v-if="childrenItem.operateType === 'choose'"
                    v-model="childrenItem.fieldValue"
                    :placeholder="$t('taskManagerInfo.Please Select')"
                    class="template-scenarios-group-children-item__child__value"
                    @change="fieldValueChange"
                    @visible-change="fieldValueVisibleChange"
                    size="small"
                    :disabled="childrenItem.symbol ===7 || childrenItem.symbol ===8 || childrenItem.symbol ===9 || childrenItem.symbol ===10"
                  >
                    <el-option
                      v-for="dict in fieldList"
                      :key="dict.fieldId"
                      :label="dict.translateTextName"
                      :value="dict.columnName"
                    />
                  </el-select>
                  <!--来自checkbox  【日期】-->
                  <el-select
                    v-if="childrenItem.operateType === 'checkbox'"
                    v-model="childrenItem.fieldValue"
                    :placeholder="$t('taskManagerInfo.Please Select')"
                    class="template-scenarios-group-children-item__child__value"
                    size="small"
                  >
                    <el-option
                      v-for="dict in checkBoxList"
                      :key="dict.fieldId"
                      :label="dict.fieldKey"
                      :value="dict.fieldId"
                    />
                  </el-select>
                  <!--来自写入 【文本】-->
                  <el-input
                    v-if="childrenItem.operateType === 'write' && fieldTypeIsText(childrenItem)"
                    v-model="childrenItem.fieldValue"
                    class="template-scenarios-group-children-item__child__value"
                    size="small"
                    :disabled="childrenItem.symbol ===7 || childrenItem.symbol ===8 || childrenItem.symbol ===9 || childrenItem.symbol ===10"
                  />

                  <!--来自写入 【数字】-->
                  <el-input-number
                    v-if="childrenItem.operateType === 'write' && fieldTypeIsNumber(childrenItem)"
                    v-model="childrenItem.fieldValue"
                    class="template-scenarios-group-children-item__child__value"
                    size="small"
                  />

                  <!--来自写入  【日期】-->
                  <el-date-picker
                    v-if="childrenItem.operateType === 'write' && fieldTypeIsDate(childrenItem)"
                    v-model="childrenItem.fieldValue"
                    class="template-scenarios-group-children-item__child__value"
                    size="small"
                    type="datetime"
                    placement="bottom-start"
                  />

                  <!-- 来自写入 【checkbox】 -->
                  <template v-if="childrenItem.operateType === 'write' && fieldTypeIsCheckbox(childrenItem)">
                    <el-select 
                    v-model="childrenItem.fieldValue" 
                    multiple placeholder="" 
                    collapse-tags 
                    size="small" 
                    class="template-scenarios-group-children-item__child__value"
                    :disabled="childrenItem.symbol ===7 || childrenItem.symbol ===8 || childrenItem.symbol ===9 || childrenItem.symbol ===10"
                  >
                      <el-option
                        v-for="(item,index ) of childrenItem.checkBoxList"
                        :key="index"
                        :label="item.text"
                        :value="item.text">
                      </el-option>
                    </el-select>
                  </template>


                  <div
                    class="
                      template-scenarios-group-children-item__child__switch
                    "
                  >
                    <div
                      class="
                        template-scenarios-group-children-item__child__write
                      "
                      :class="
                        childrenItem.operateType === 'write'
                          ? 'operate_type_checked'
                          : ''
                      "
                      @click="handleWrite(childrenItem)"
                    >
                      {{ $t('taskManagerInfo.Write') }}
                    </div>
                    <div
                      v-if="!fieldTypeIsDate(childrenItem)"
                      class="
                        template-scenarios-group-children-item__child__choose
                      "
                      :class="
                        childrenItem.operateType === 'choose'
                          ? 'operate_type_checked'
                          : ''
                      "
                      @click="handleChoose(childrenItem)"
                    >
                      {{ $t('taskManagerInfo.Choice') }}
                    </div>
                    <div
                      v-if="fieldTypeIsDate(childrenItem)"
                      class="
                        template-scenarios-group-children-item__child__choose2
                      "
                      :class="
                        childrenItem.operateType === 'choose'
                          ? 'operate_type_checked'
                          : ''
                      "
                      @click="handleChoose(childrenItem)"
                    >
                      {{ $t('taskManagerInfo.Choice') }}
                    </div>
                    <div
                      v-if="fieldTypeIsDate(childrenItem)"
                      class="template-scenarios-group-children-item__child__choose"
                      :class="
                        childrenItem.operateType === 'checkbox'
                          ? 'operate_type_checked'
                          : ''
                      "
                      @click="handleCheckbox(childrenItem)"
                    >
                      {{ $t('taskManagerInfo.Function') }}
                    </div>
                  </div>
                  <img
                    class="template-scenarios-group-children-item__group__del"
                    src="@/assets/img/ic_delete.png"
                    @click="handleRemoveChildChild(parentItem, childrenIndex)"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import modelUi from '@/views/taskManagement/taskManger/utils/modelUi.js'

import { getScenario } from '@/libs/api/taskManger.js'

import { getDateSymbolList, getNumberSymbolList, getTextSymbolList } from '@/views/taskManagement/taskManger/utils/dictUtil.js'

import { parseResp, parseError } from '@/views/taskManagement/taskManger/utils/requestUtil.js'

export default {
  props: {
    form: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      loading: false,
      taskTriggerCondition: {
        itemType: 'group',
        itemId: Date.now(),
        condition: '',
        children: [],
      },
      conditionTitleList: [
        {
          dictName: this.$t('taskManagerInfo.Meet any of the following conditions'),
          dictValue: 'or',
        },
        {
          dictName: this.$t('taskManagerInfo.All of the following conditions are meet'),
          dictValue: 'and',
        },
      ],
      symbolList: [],
      fieldList: [],
      checkBoxList: [
        {
          fieldId: '1',
          fieldKey: 'Now()',
        },
        {
          fieldId: '2',
          fieldKey: 'Now(date)',
        },
        {
          fieldId: '3',
          fieldKey: 'Now(time)',
        },
      ],
    }
  },

  created() {
    let taskTempCondition = this.form.taskTriggerCondition
    if (taskTempCondition) {
      taskTempCondition = JSON.parse(taskTempCondition)
      if (taskTempCondition.hasOwnProperty('and')) {
        const tempAndList = taskTempCondition.and
        const children = this.getChildren(tempAndList)
        this.taskTriggerCondition = {
          itemType: 'group',
          itemId: Date.now(),
          condition: 'and',
          children,
        }
      } else if (taskTempCondition.hasOwnProperty('or')) {
        const tempOrList = taskTempCondition.or
        const children = this.getChildren(tempOrList)
        this.taskTriggerCondition = {
          itemType: 'group',
          itemId: Date.now(),
          condition: 'or',
          children,
        }
      } else {
        this.taskTriggerCondition = {
          itemType: 'group',
          itemId: Date.now(),
          condition: '',
          children: [],
        }
      }
    } else {
      this.taskTriggerCondition = {
        itemType: 'group',
        itemId: Date.now(),
        condition: '',
        children: [],
      }
    }

    // this.symbolList = getSymbolList()
    this.getScenarioReq()
  },

  methods: {
    getChildren(list) {
      const children = []
      list.forEach(listItem => {
        if (listItem.hasOwnProperty('and')) {
          const tempAndList = listItem.and
          const children2 = this.getChildren(tempAndList)
          children.push({
            itemType: 'group',
            itemId: Date.now(),
            condition: 'and',
            children: children2,
          })
        } else if (listItem.hasOwnProperty('or')) {
          const tempOrList = listItem.or
          const children2 = this.getChildren(tempOrList)
          children.push({
            itemType: 'group',
            itemId: Date.now(),
            condition: 'or',
            children: children2,
          })
        } else {
          const { fieldName } = listItem
          const { symbol } = listItem
          const { isSystemField } = listItem
          const { fieldType } = listItem
          const { fieldValue } = listItem
          children.push({
            itemType: 'child',
            itemId: Date.now(),
            fieldName,
            symbol,
            isSystemField,
            fieldType,
            fieldValue,
            symbolList: this.getSymbolList(fieldType),
            operateType: isSystemField === 0 ? 'write' : isSystemField === 1 ? 'choose' : 'checkbox', // write|choose | checkbox
          })
        }
      })
      return children
    },

    getSymbolList(fieldType) {
      console.log('----fieldType',fieldType)
      if (fieldType === 'text' || fieldType === 'url'
        || fieldType === 'radio' || fieldType === 'checkbox'
        || fieldType === 'img'
      ) {
        const list = getTextSymbolList()
        return list
      }
      if (fieldType === 'number') {
        const list = getNumberSymbolList()
        return list
      }
      if (fieldType === 'date') {
        const list = getDateSymbolList()
        return list
      }
      return []
    },

    // 条件标题改变
    conditionTitleChange(val, object) {},

    // 添加子
    handleAddChild() {
      this.taskTriggerCondition.children.push({
        itemType: 'child',
        itemId: Date.now(),
        fieldName: '',
        symbol: '',
        isSystemField: 1,
        fieldType: '',
        fieldValue: '',
        symbolList: [],
        operateType: 'choose', // write|choose
      })
    },

    // 添加组
    handleAddGroup() {
      this.taskTriggerCondition.children.push({
        itemType: 'group',
        itemId: Date.now(),
        condition: '',
        children: [],
      })
    },

    // fieldName改变
    fieldNameChange(val, objectItem) {
      // eslint-disable-next-line no-unused-vars
      const fieldItem = val
        ? this.fieldList.find(ele => ele.columnName === val)
        : {}
      // eslint-disable-next-line no-param-reassign
      objectItem.fieldType = fieldItem.type
      objectItem.symbolList = this.getSymbolList(fieldItem.type)
    },

    // fieldName改变
    fieldNameVisibleChange(val) {
      if (val && this.fieldList.length === 0) {
        this.getScenarioReq()
      }
    },

    fieldValueChange(val) {},
    fieldValueVisibleChange(val) {
      if (val && this.fieldList.length === 0) {
        this.getScenarioReq()
      }
    },

    handleWrite(objectItem) {
      // eslint-disable-next-line no-param-reassign
      objectItem.operateType = 'write'
      objectItem.fieldValue = ''
      // eslint-disable-next-line no-param-reassign
      objectItem.isSystemField = 0
    },

    handleChoose(objectItem) {
      // eslint-disable-next-line no-param-reassign
      objectItem.operateType = 'choose'
      objectItem.fieldValue = ''
      // eslint-disable-next-line no-param-reassign
      objectItem.isSystemField = 1
    },

    handleCheckbox(objectItem) {
      // eslint-disable-next-line no-param-reassign
      objectItem.operateType = 'checkbox'
      objectItem.fieldValue = ''
      // eslint-disable-next-line no-param-reassign
      objectItem.isSystemField = 2
    },

    handleRemoveChild(index) {
      this.taskTriggerCondition.children.splice(index, 1)
    },

    handleAddChildChild(parentItem) {
      const children = parentItem.children || []
      children.push({
        itemType: 'child',
        itemId: Date.now(),
        fieldName: '',
        symbol: '',
        isSystemField: 1,
        fieldType: '',
        fieldValue: '',
        symbolList: [],
        operateType: 'choose', // write|choose
      })
    },

    handleRemoveChildChild(parentItem, childrenIndex) {
      parentItem.children.splice(childrenIndex, 1)
    },

    fieldTypeIsText(objectItem) {
      if (
        objectItem.fieldType === 'text'
        || objectItem.fieldType === 'url'
        || objectItem.fieldType === 'radio'
        // || objectItem.fieldType === 'checkbox'
        || objectItem.fieldType === 'img'
      ) {
        return true
      }
      return false
    },

    fieldTypeIsNumber(objectItem) {
      if (objectItem.fieldType === 'number') {
        return true
      }
      return false
    },

    fieldTypeIsDate(objectItem) {
      if (objectItem.fieldType === 'date') {
        return true
      }
      return false
    },
    fieldTypeIsCheckbox(objectItem) {
      let fieldList = this.fieldList
      let checkBoxList = []
      for(var i=0;i<fieldList.length;i++){
        if(fieldList[i].columnName === objectItem.fieldName){
          checkBoxList = fieldList[i].checkBoxList
        }
      }
      objectItem.checkBoxList = checkBoxList
      // objectItem.fieldValue = ''
      console.log('----fieldTypeIsCheckbox',objectItem)
      if (objectItem.fieldType === 'checkbox') {
        return true
      }
      return false
    },

    getScenarioReq() {
      this.loading = true
      getScenario(this.form.scenario)
        .then(res => {
          this.loading = false
          const result = parseResp(res)
          if (result.success) {
            this.fieldList = result.data.fieldList || []
          } else {
            modelUi.msgError(result.message)
          }
        })
        .catch(err => {
          this.loading = false
          modelUi.msgError(parseError(err))
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.template-scenarios-group {
  list-style: none;
  position: relative;
}

.template-scenarios-group:first-child:before {
  border-style: none none none none;
}

.template-scenarios-group:before {
  position: absolute;
  content: "";
  top: -1.01em;
  left: -30px;
  width: 25px;
  height: 52px;
  border-style: none none solid solid;
  border-width: 0.05em;
  border-color: #d3d3d3;
}

.template-scenarios-group:not(:last-child):after {
  position: absolute;
  content: "";
  // top: 50px;
  top: 0px;
  left: -30px;
  bottom: 0;
  border-style: none none none solid;
  border-width: 0.05em;
  border-color: #d3d3d3;
}

.template-scenarios {
  display: flex;
  flex-direction: column;
  margin: 10px 50px;
}

.template-scenarios-group {
  display: flex;
  flex-direction: column;
}

.template-scenarios-group-children-item__group {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.template-scenarios-group-children-item__group__select {
  width: 350px;
}

.template-scenarios-group-children-item__group__empty {
  flex: 1;
}

.template-scenarios-group-children-item__group__del {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}

.template-scenarios-group-children-item__group__add {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}

.template-scenarios-group-children-item__group__tree {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}

.template-scenarios-group-children {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}

.template-scenarios-group-children__line {
  width: 0.05em;
  height: 20px;
  background: #d3d3d3;
  margin-left: -30px;
}

.template-scenarios-group-children-item__child {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.template-scenarios-group-children-item__child__key {
  width: 220px;
  margin-right: 10px;
}

.template-scenarios-group-children-item__child__operator {
  // width: 220px;
  width: 150px;
  margin-right: 10px;
}

.template-scenarios-group-children-item__child__value {
  width: 220px;
  margin-right: 10px;
}
::v-deep .template-scenarios-group-children-item__child__value.el-date-editor--datetime .el-input__inner{
  width: 200px;
}

.template-scenarios-group-children-item__child__switch {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.template-scenarios-group-children-item__child__write {
  border: 1px solid #3461ff;
  height: 32px;
  width: 70px;
  color: #3461ff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
}

.template-scenarios-group-children-item__child__choose {
  border: 1px solid #3461ff;
  height: 32px;
  width: 70px;
  color: #3461ff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
}

.template-scenarios-group-children-item__child__choose2 {
  border: 1px solid #3461ff;
  height: 32px;
  width: 70px;
  color: #3461ff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
}
.template-scenarios-group-children-item__child__write  + .template-scenarios-group-children-item__child__choose2,
.template-scenarios-group-children-item__child__choose2 + .template-scenarios-group-children-item__child__choose{
  border-left: none;
}

.operate_type_checked {
  background-color: #3461ff;
  color: #ffffff;
}
</style>

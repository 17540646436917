/* eslint-disable no-prototype-builtins */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-prototype-builtins */
<template>
  <el-dialog
    v-if="dialogVisible"
    :visible.sync="dialogVisible"
    :before-close="cancel"
    :close-on-click-modal="false"
    append-to-body
    width="820px"
    class="task-manger-switch-template-one-dialog"
    style="padding: 10px"
  >
    <div
      slot="title"
      class="dialog-header"
    >
      <div class="dialog-index">
        2
      </div>
      <span class="dialog-title">{{ $t('taskManagerInfo.Select a Template Type (Switch Template)') }}</span>
    </div>
    <div
      v-if="dialogVisible"
      v-loading="loading"
    >
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="135px"
        size="small"
      >
        <el-form-item
          :label="$t('taskManagerInfo.Template Type')"
          prop="templateType"
        >
          <el-select
            v-model="form.templateType"
            :placeholder="$t('taskManagerInfo.Select a tmplate type')"
            style="width: 100%"
            clearable
            @change="templateTypeChange"
            @visible-change="templateTypeVisibleChange"
          >
            <el-option
              v-for="dict in templateTypeList"
              :key="dict.templateTypeId"
              :label="dict.templateTypeName"
              :value="dict.templateTypeId"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('taskManagerInfo.Pages')"
          prop="pageNo"
        >
          <el-select
            v-model="form.pageNo"
            placeholder=""
            style="width: 100%"
            clearable
          >
            <el-option
              v-for="item in pageList"
              :key="item.dictValue"
              :label="item.dictName"
              :value="item.dictValue"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('taskManagerInfo.Force Transmit')">
          <el-checkbox v-model="isForce" />
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer__template__1"
      >
        <el-button
          class="button-next gray-button"
          size="small"
          @click="handlePrior"
        >
          {{ $t("taskManagerInfo.Prior") }}
        </el-button>
        <el-button
          class="button-next green-button"
          size="small"
          @click="handleNext"
        >
          {{ $t("taskManagerInfo.Next") }}
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import modelUi from '@/views/taskManagement/taskManger/utils/modelUi.js'

import { getTemplateTypeList } from '@/libs/api/templateType.js'

import { getSelectedStoreId } from '@/views/taskManagement/taskManger/utils/cacheUtil.js'

import { parseResp, parseError } from '@/views/taskManagement/taskManger/utils/requestUtil.js'
// import { pageNumberList } from '@/libs/enum'
export default {
  components: {},
  data() {
    return {
      // pageNumberList,
      dialogVisible: false,
      isForce: false,
      loading: false,
      form: {},
      srcForm: {},
      templateTypeList: [],
      pageList: [],
      rules: {
        templateType: {
          required: true,
          message: this.$t('taskManagerInfo.Select a tmplate type'),
          trigger: 'change',
        },
        pageNo: { required: true, message: this.$t('taskManagerInfo.Select a Page'), trigger: 'change' },
      },
    }
  },
  methods: {
    openDialog(row) {
      console.log('切模板---',row)
      this.form = row
      this.srcForm = JSON.parse(JSON.stringify(row))
      if (this.form.force === 0) {
        this.isForce = true
      } else {
        this.isForce = false
      }

      this.dialogVisible = true
      if (this.templateTypeList.length === 0) {
        this.scenarioId = row.scenario
        this.getTemplateTypeListReq(row.scenario)
      }

      const pageList = []
      pageList.push({
        dictValue: 1,
        dictName: this.$t('taskManagerInfo.page1'),
      })
      pageList.push({
        dictValue: 2,
        dictName: this.$t('taskManagerInfo.page2'),
      })
      pageList.push({
        dictValue: 3,
        dictName: this.$t('taskManagerInfo.page3'),
      })
      pageList.push({
        dictValue: 4,
        dictName: this.$t('taskManagerInfo.page4'),
      })
      pageList.push({
        dictValue: 5,
        dictName: this.$t('taskManagerInfo.page5'),
      })
      this.pageList = pageList
    },
    cancel() {
      this.dialogVisible = false
    },
    templateTypeChange() {},
    templateTypeVisibleChange(val) {
      if (val && this.templateTypeList.length === 0) {
        this.getTemplateTypeListReq(this.scenarioId)
      }
    },
    handlePrior() {
      this.$emit('stepOne', this.srcForm)
      this.dialogVisible = false
    },
    handleNext() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.isForce) {
            this.form.force = 0
          } else {
            this.form.force = 1
          }
          this.$emit('templateTwo', this.form)
          this.dialogVisible = false
        }
      })
    },

    getTemplateTypeListReq(scenarioId) {
      this.loading = true
      getTemplateTypeList({
        templateTypeName: '',
        storeId: getSelectedStoreId(),
        scenarioId
      })
        .then(res => {
          this.loading = false
          const result = parseResp(res)
          if (result.success) {
            this.templateTypeList = result.data || []
          } else {
            modelUi.msgError(result.message)
          }
        })
        .catch(err => {
          this.loading = false
          modelUi.msgError(parseError(err))
        })
    },
  },
}
</script>

<style lang="scss" scope>
.task-manger-switch-template-one-dialog {
  .dialog-header {
    display: flex;
    align-items: center;
  }
  .dialog-index {
    width: 26px;
    height: 26px;
    line-height: 26px;
    font-size: 14px;
    background: linear-gradient(199deg, #29f19c 0%, #14cad5 100%);
    border-radius: 0px 20px 20px 20px;
    text-align: center;
    font-family: Impact;
    font-weight: 400;
    color: #ffffff;
  }

  .dialog-title {
    margin-left: 10px;
    font-size: 16px;
    font-family: Verdana;
    font-weight: 400;
    color: #35b871;
  }

  .el-dialog__header {
    background-color: #E9F9EF;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom: #D3F4E1;
  }

  .el-dialog__body {
    padding: 30px 20px !important;
  }

  .dialog-footer__template__1 {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }

  .button-next {
    // border: 0px;
    width: 110px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
</style>

<template>
  <el-dialog
    v-if="dialogVisible"
    :visible.sync="dialogVisible"
    :before-close="cancel"
    :close-on-click-modal="false"
    append-to-body
    width="820px"
    class="task-manger-step-four-dialog"
    style="padding: 10px"
  >
    <div slot="title" class="dialog-header">
      <div class="dialog-index">4</div>
      <span class="dialog-title">{{ $t('taskManagerInfo.Sechdule a plan to execute this task') }}</span>
    </div>
    <div
      v-if="dialogVisible"
      v-loading="loading"
    >
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="145px"
        size="small"
      >
        <!-- <el-form-item :label="$t('taskManagerInfo.Start Time')">
          <el-date-picker
            v-model="startDate"
            type="datetime"
            placement="bottom-start"
            :picker-options="startPickerOptions"
            @change="handleDateChange"
            placeholder="">
          </el-date-picker>

          <el-date-picker
            v-model="datePickerValue"
            @change="handleDateChange"
            type="datetimerange"
            range-separator="-"
            start-placeholder=""
            placement="bottom-start"
            end-placeholder="">
          </el-date-picker>

          <el-date-picker
            v-model="startDate"
            style="width: 45%; margin-right: 5%"
            type="date"
            :picker-options="startPickerOptions"
            @change="handleStartDateChange"
            placement="bottom-start"
          />

          <el-time-select
            v-model="startTime"
            style="width:45%；margin-left:5%"
            @change="handleStartTimeChange"
            placement="bottom-start"
          />
        </el-form-item> -->
        <!-- <el-form-item :label="$t('taskManagerInfo.End Time')">
          <el-date-picker
            v-model="endDate"
            type="datetime"
            :picker-options="endPickerOptions"
            placement="bottom-start"
            placeholder="">
          </el-date-picker>

          <el-date-picker
            v-model="endDate"
            style="width: 45%; margin-right: 5%"
            type="date"
            :picker-options="endPickerOptions"
            placement="bottom-start"
          />

          <el-time-select
            v-model="endTime"
            style="width:45%；margin-left:5%"
            placement="bottom-start"
          />
        </el-form-item> -->
        <el-form-item :label="$t('taskManagerInfo.Start Time')+'-'+$t('taskManagerInfo.End Time')" prop="datePickerValue">
          <el-date-picker
            v-model="datePickerValue"
            @change="handleDateChange"
            type="datetimerange"
            range-separator="-"
            start-placeholder=""
            :picker-options="startPickerOptions"
            placement="bottom-start"
            style="width: 84%"
            end-placeholder="">
          </el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('taskManagerInfo.Cycle')">
          <el-checkbox v-model="isCycle" />
        </el-form-item>

        <el-form-item
          :label="$t('taskManagerInfo.Frequency')"
          prop="frequency"
        >
          <el-select
            v-model="form.frequency"
            style="width: 84%"
            @change="frequencyChange"
            @visible-change="frequencyVisibleChange"
            clearable
            :disabled="!isCycle"
          >
            <el-option
              v-for="dict in frequencyList"
              :key="dict.dictValue"
              :label="dict.dictName"
              :value="dict.dictValue"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer__four"
      >
        <el-button
          class="task-manger-button-next gray-button"
          @click="handlePrior"
          size="small"
        >
          {{ $t('taskManagerInfo.Prior') }}
        </el-button>
        <el-button
          class="task-manger-button-next green-button"
          @click="handleNext"
          size="small"
        >
          {{ $t('taskManagerInfo.Complete') }}
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import modelUi from '@/views/taskManagement/taskManger/utils/modelUi.js'

import { createSchedule, updateSchedule } from '@/libs/api/taskManger.js'

import { dateFormat } from '@/views/taskManagement/taskManger/utils/dateUtil.js'

import typeUtil from '@/views/taskManagement/taskManger/utils/typeUtil.js'

import { parseResp, parseError } from '@/views/taskManagement/taskManger/utils/requestUtil.js'

export default {
  components: {},
  data() {
    const validateStartTime = (rule, value, callback) => {
      if (!this.startDate) {
        callback(this.$t('taskManagerInfo.Select a StartDate'))
      } else if (!this.startTime) {
        callback(this.$t('taskManagerInfo.Select a StartTime'))
      } else {
        callback()
      }
    }
    return {
      loading: false,
      isCycle: false,
      dialogVisible: false,
      startDate: '',
      startTime: '',
      endDate: null,
      endTime: null,
      srcForm: {},
      form: {
        startTime:'', //任务开始时间
        endTime:'', // 任务结束时间
        datePickerValue:''
      },
      datePickerValue:'',
      rules: {
        // frequency: {
        //   required: true,
        //   message: this.$t('taskManagerInfo.Select a Frequency'),
        //   trigger: 'change',
        // },
        // datePickerValue:{
        //   required: true,
        //   message: this.$t('formValidate.required fields'),
        //   trigger: 'change',
        // },
      },
      startPickerOptions: this.startEnable(),
      endPickerOptions: this.endEnable(),
      frequencyList: [
        // {
        //   dictValue: 'MINUTE',
        //   dictName: '分钟',
        // },
        {
          dictValue: 'HOUR',
          dictName: this.$t('taskManagerInfo.Hour'),
        },
        {
          dictValue: 'DAY',
          dictName: this.$t('taskManagerInfo.Day'),
        },
        {
          dictValue: 'WEEK',
          dictName: this.$t('taskManagerInfo.Week'),
        },
        {
          dictValue: 'TWO WEEK',
          dictName: this.$t('taskManagerInfo.Two week'),
        },
        {
          dictValue: 'MONTH',
          dictName:  this.$t('taskManagerInfo.Month'),
        },
        {
          dictValue: 'YEAR',
          dictName: this.$t('taskManagerInfo.Year'),
        },
      ],
    }
  },
  methods: {
    startEnable() {
      const that = this
      return {
        disabledDate(time) {
          // 选择的日期大于当前日期
          if (that.form.ledStartTime) {
            if (typeUtil.isString(that.form.ledStartTime)) {
              return (
                time.getTime() < new Date(that.form.ledStartTime).getTime() - 8.64e7
              )
            }
            if (typeUtil.isDate(that.form.ledStartTime)) {
              return time.getTime() < that.form.ledStartTime.getTime() - 8.64e7
            }
            return time.getTime() < Date.now() - 8.64e7
          }
          // console.log('----startEnable',time.getTime(), Date.now())
          return time.getTime() < Date.now() - 8.64e7
          // return time.getTime() < new Date().getTime()
        },
      }
    },

    endEnable() {
      const that = this
      return {
        disabledDate(time) {
          // 选择的日期大于当前日期
          if (that.startDate) {
            return time.getTime() < that.startDate.getTime()
          }
          if (that.form.ledStartTime) {
            if (typeUtil.isString(that.form.ledStartTime)) {
              return (
                time.getTime() < new Date(that.form.ledStartTime).getTime()
              )
            }
            if (typeUtil.isDate(that.form.ledStartTime)) {
              return time.getTime() < that.form.ledStartTime.getTime()
            }
            return time.getTime() < Date.now()
          }

          return time.getTime() < Date.now()
        },
      }
    },

    // 时间选择change
    handleDateChange(val){
      console.log('时间选择---',val)
      if(val){
        this.form.startTime = val[0];
        this.form.endTime = val[1];
      } else {
        this.form.startTime = '';
        this.form.endTime =  '';
      }
    },

    handleStartDateChange() {
      this.endDate = null
      this.endTime = null
    },
    handleStartTimeChange() {},
    openDialog(row) {
      this.srcForm = JSON.parse(JSON.stringify(row))
      this.form = row
      if (this.form.cycle === 0) {
        this.isCycle = false
      } else {
        this.isCycle = true
      }
      this.dialogVisible = true

      if (this.form.startTime) {
        this.startDate = this.form.startTime
        this.startTime = dateFormat(this.form.startTime, 'HH:mm')
        var arr = [this.form.startTime,this.form.endTime]
        console.log('编辑---',arr)
        this.datePickerValue=[...arr]
      } else {
        this.startDate = null
        this.startTime = ''
        this.datePickerValue=''
      }

      if (this.form.endTime) {
        this.endDate = this.form.endTime
        this.endTime = dateFormat(this.form.endTime, 'HH:mm')
      } else {
        this.endDate = null
        this.endTime = ''
      }
    },
    cancel() {
      this.dialogVisible = false
    },
    frequencyChange() {},
    frequencyVisibleChange() {},

    handlePrior() {
      this.$emit('stepThree', this.srcForm)
      this.dialogVisible = false
    },

    handleNext11() {
      if (!this.startDate) {
        modelUi.msgError(this.$t('taskManagerInfo.Select StartDate'))
        return
      }

      if (!this.startTime) {
        modelUi.msgError(this.$t('taskManagerInfo.Select StartTime'))
        return
      }

      if (!this.endDate) {
        modelUi.msgError(this.$t('taskManagerInfo.Select EndDate'))
        return
      }

      if (!this.endTime) {
        modelUi.msgError(this.$t('taskManagerInfo.Select EndTime'))
        return
      }

      if (this.startDate && this.startTime) {
        const startDateTime = `${dateFormat(this.startDate, 'yyyy-MM-dd')} ${
          this.startTime
        }:00`
        this.form.startTime = new Date(startDateTime)
      } else if (this.startDate) {
        this.form.startTime = this.startDate
      } else {
        this.form.startTime = null
      }

      if (this.endDate && this.endTime) {
        const endTime = `${dateFormat(this.endDate, 'yyyy-MM-dd')} ${
          this.endTime
        }:00`
        this.form.endTime = new Date(endTime)
      } else if (this.endDate) {
        this.form.endTime = this.endDate
      } else {
        this.form.endTime = null
      }

      if (!this.form.startTime) {
        modelUi.msgError(this.$t('taskManagerInfo.Please Select Start Time'))
        return
      }

      if (!this.form.endTime) {
        modelUi.msgError(this.$t('taskManagerInfo.Please Select End Time'))
        return
      }

      if (this.form.startTime && this.form.endTime) {
        if (this.form.startTime.getTime() >= this.form.endTime.getTime()) {
          modelUi.msgError(this.$t('taskManagerInfo.Start Time >= End Time'))
          return
        }
      }

      if (this.isCycle) {
        this.form.cycle = 1
      } else {
        this.form.cycle = 0
      }

      this.$refs.form.validate(valid => {
        if (valid) {
          this.srcForm = JSON.parse(JSON.stringify(this.form))
          // 提交
          if (this.form.taskScheduleId) {
            this.loading = true
            updateSchedule(this.form)
              .then(res => {
                this.loading = false
                const result = parseResp(res)
                console.log('------result',result)
                if (result.success) {
                  this.$emit('submit')
                  this.dialogVisible = false
                } else {
                  modelUi.msgError(result.message)
                }
              })
              .catch(err => {
                this.loading = false
                modelUi.msgError(parseError(err))
              })
          } else {
            this.loading = true
            console.log('----this.form',this.form)
            createSchedule(this.form)
              .then(res => {
                this.loading = false
                const result = parseResp(res)
                if (result.success) {
                  this.$emit('submit')
                  this.dialogVisible = false
                } else {
                  modelUi.msgError(result.message)
                }
              })
              .catch(err => {
                this.loading = false
                modelUi.msgError(parseError(err))
              })
          }
        }
      })
    },
    handleNext(){
      if(!this.datePickerValue){
        this.$message.error(this.$t('taskManagerInfo.Select StartDate'))
        return
      }
      if (this.isCycle) {
        this.form.cycle = 1
        if(!this.form.frequency){
          this.$message.error(this.$t('taskManagerInfo.Select a Frequency'))
          return
        }
      } else {
        this.form.cycle = 0
      }

      this.$refs.form.validate(valid => {
        if (valid) {
          this.srcForm = JSON.parse(JSON.stringify(this.form))
          // 提交
          if (this.form.taskScheduleId) {
            this.loading = true
            updateSchedule(this.form)
              .then(res => {
                this.loading = false
                const result = parseResp(res)
                console.log('------result',result)
                if (result.success) {
                  this.$emit('submit')
                  this.dialogVisible = false
                } else {
                  this.$message.error(result.message)
                  // modelUi.msgError(result.message)
                }
              })
              .catch(err => {
                this.loading = false
                modelUi.msgError(parseError(err))
              })
          } else {
            this.loading = true
            console.log('----this.form',this.form)
            createSchedule(this.form)
              .then(res => {
                this.loading = false
                const result = parseResp(res)
                if (result.success) {
                  this.$emit('submit')
                  this.dialogVisible = false
                } else {
                  modelUi.msgError(result.message)
                }
              })
              .catch(err => {
                this.loading = false
                modelUi.msgError(parseError(err))
              })
          }
        }
      })
    }
  },
}
</script>

<style lang="scss" scope>
.task-manger-step-four-dialog {
  .dialog-header {
    display: flex;
    align-items: center;
  }
  .dialog-index {
    width: 26px;
    height: 26px;
    line-height: 26px;
    font-size: 14px;
    background: linear-gradient(199deg, #29f19c 0%, #14cad5 100%);
    border-radius: 0px 20px 20px 20px;
    text-align: center;
    font-family: Impact;
    font-weight: 400;
    color: #ffffff;
  }

  .dialog-title {
    margin-left: 10px;
    font-size: 16px;
    font-family: Verdana;
    font-weight: 400;
    color: #35b871;
  }

  .el-dialog__header {
    background-color: #E9F9EF;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom: #D3F4E1;
  }

  .el-dialog__body {
    padding: 30px 20px !important;
  }

  .dialog-footer__four {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }

  .task-manger-button-next {
    // border: 0px;
    width: 110px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
</style>

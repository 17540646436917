<template>
  <el-dialog
    v-if="dialogVisible"
    :visible.sync="dialogVisible"
    :before-close="cancel"
    :close-on-click-modal="false"
    append-to-body
    width="820px"
    class="task-manger-switch-layout-one-dialog"
    style="padding: 10px"
  >
    <div
      slot="title"
      class="dialog-header"
    >
      <div class="dialog-index">
        2
      </div>
      <span class="dialog-title">{{$t('taskManagerInfo.Select a Layout (Switch Layout)')}}</span>
    </div>

    <div v-if="dialogVisible">
      <div>
        <div class="search-condition">
          <div class="search-item">
            <span class="search-item__span"><span class="search-item__red">*</span> {{$t('taskManagerInfo.Pages')}}</span>
            <el-select
              v-model="form.pageNo"
              class="search-item__select"
              clearable
              @change="handlePageNoChange"
              size="small"
            >
              <el-option
                v-for="dict in pageList"
                :key="dict.dictValue"
                :label="dict.dictName"
                :value="dict.dictValue"
              />
            </el-select>
            <span style="margin:0 10px 0 20px" >{{$t('taskManagerInfo.Force Transmit')}}</span>
            <el-checkbox v-model="isForce" />
          </div>
        </div>

        <div class="search-condition">
          <div class="search-item">
            <span class="search-item__span"><span class="search-item__red">*</span> {{$t('Layout')}}</span>
            <el-select
              v-model="form.layoutType"
              class="search-item__select"
              clearable
              @change="handleLayutTypeChange"
              size="small"
            >
              <el-option
                v-for="dict in layoutTypeList"
                :key="dict.dictValue"
                :label="dict.dictName"
                :value="dict.dictValue"
              />
            </el-select>
          </div>
          <div class="search-item">
            <el-input
              clearable
              v-model="layoutNameKeyword"
              class="search-item__input"
              :placeholder="$t('taskManagerInfo.Input layout name')"
              size="small"
            />
          </div>
          <div class="search-item">
            <div
              class="search-item__button"
              @click="handleSearch"
            >
              {{$t('taskManagerInfo.Search')}}
            </div>
          </div>
        </div>

        <div class="result-box">
          <div
            v-if="tableData && tableData.length > 0"
            class="result-list"
          >
            <div
              v-for="item in tableData"
              :key="item.layoutId"
              class="result-item"
            >
              <el-image
                fit="cover"
                class="result-img"
                :src="item.layoutImageUrl"
              />
              <div
                class="result-name"
                :title="item.title"
              >{{ item.title }}</div>
              <div
                v-if="item.checked"
                class="result-status__checked"
                @click="handleCheck(item)"
              />
              <div
                v-else
                class="result-status__uncheck"
                @click="handleCheck(item)"
              />
            </div>
          </div>
          <div class="result-page">
            <!-- <pagination :tableInfo="pageParams" @changePage="getLayoutListReq" /> -->
            <el-pagination
              background
              :hide-on-single-page="true"
              :current-page="currentPage"
              :page-size="pageSize"
              layout="prev, pager, next"
              :total="total"
              @current-change="handleChangePage"
            />
          </div>
        </div>
        <div
          v-if="showEmpty"
          class="result-empty"
        >
           {{$t('taskManagerInfo.No data')}}
        </div>
      </div>
      <div
        slot="footer"
        class="dialog-footer__layout__1"
      >
        <el-button
          class="button-next gray-button"
          @click="handlePrior"
          size="small"
        >
           {{$t('taskManagerInfo.Prior')}}
        </el-button>
        <el-button
          class="button-next green-button"
          @click="handleNext"
          size="small"
        >
           {{$t('taskManagerInfo.Next')}}
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import modelUi from '@/views/taskManagement/taskManger/utils/modelUi.js'

import { getLayoutList } from '@/libs/api/taskManger.js'

import { getSelectedStoreId } from '@/views/taskManagement/taskManger/utils/cacheUtil.js'

import { parseResp, parseError } from '@/views/taskManagement/taskManger/utils/requestUtil.js'
import pagination from "@/components/pagination";
// import { pageNumberList } from '@/libs/enum'
export default {
  components: { pagination },
  data() {
    return {
      // pageNumberList,
      dialogVisible: false,
      isForce: false,
      layoutNameKeyword: '',
      srcForm: {},
      form: {},
      showEmpty: false,
      layoutTypeList: [
        {
          dictValue: 0,
          dictName: this.$t('taskManagerInfo.Normal'),
        },
        {
          dictValue: 1,
          dictName: this.$t('taskManagerInfo.Split Screen'),
        },
        {
          dictValue: 2,
          dictName: this.$t('taskManagerInfo.Self-Adaption'),
        },
      ],
      pageList: [],
      totalData: [],
      tableData: [],
      total: 0,
      currentPage: 1,
      pageSize: 10,
      pageParams: {
        current: 1,
        size: 3,
        total: 20,
      },
      rules: {
        templateType: {
          required: true,
          message: this.$t('taskManagerInfo.Select a tmplate type'),
          trigger: 'change',
        },
        pageNo: { required: true, message: this.$t('taskManagerInfo.Select a page'), trigger: 'change' },
      },
    }
  },
  methods: {
    openDialog(row) {
      this.srcForm = JSON.parse(JSON.stringify(row))
      this.form = row

      if (this.form.force === 0) {
        this.isForce = true
      } else {
        this.isForce = false
      }
      this.dialogVisible = true

      const pageList = []
      pageList.push({
        dictValue: 1,
        dictName: this.$t('taskManagerInfo.page1'),
      })
      pageList.push({
        dictValue: 2,
        dictName: this.$t('taskManagerInfo.page2'),
      })
      pageList.push({
        dictValue: 3,
        dictName: this.$t('taskManagerInfo.page3'),
      })
      pageList.push({
        dictValue: 4,
        dictName: this.$t('taskManagerInfo.page4'),
      })
      pageList.push({
        dictValue: 5,
        dictName: this.$t('taskManagerInfo.page5'),
      })
      this.pageList = pageList

      this.getLayoutListReq()
    },
    cancel() {
      this.dialogVisible = false
    },

    handleLayutTypeChange() {
      this.$set(this.form, 'pageNo', 1)
      this.getLayoutListReq()
    },

    handleSearch() {
      this.getLayoutListReq()
    },

    handlePageNoChange() {
      this.getLayoutListReq()
    },

    handleChangePage(val) {
      this.getTableData(val)
    },

    handleCheck(item) {
      const list = this.tableData || []
      list.forEach(listItem => {
        if (listItem.layoutId === item.layoutId) {
          listItem.checked = true
        } else {
          listItem.checked = false
        }
      })
      this.tableData = list
    },

    getLayoutListReq() {
      const storeId = getSelectedStoreId()
      const storeQueryType = 2
      const current = this.form.pageNo
      const size = 20
      // const size = this.pageParams.size
      const orders = []
      const strategyName = this.layoutNameKeyword || ''
      const strategyType = this.form.layoutType
      getLayoutList(storeId, storeQueryType, current, size, orders, strategyName, strategyType)
        .then(res => {
          const result = parseResp(res)
          if (result.success) {
            const totalData = []
            const records = result.data || []
            records.forEach(recordItem => {
              const children = recordItem.children || []
              children.forEach(childrenItem => {
                const { layoutStrategyName, layoutStrategyId } = childrenItem
                const children2 = childrenItem.children || []
                children2.forEach(children2Item => {
                  children2Item.layoutStrategyName = layoutStrategyName
                  children2Item.layoutStrategyId = layoutStrategyId
                  children2Item.checked = false
                  children2Item.title = children2Item.layoutStrategyName + "_" + children2Item.layoutName
                })
                totalData.push(...children2)
              })
            })
            this.total = totalData.length
            this.pageParams.total = totalData.length
            this.totalData = totalData
            this.getTableData(1)
            if (this.total === 0) {
              this.showEmpty = true
            } else {
              this.showEmpty = false
            }
          } else {
            modelUi.msgError(result.message)
          }
        })
        .catch(err => {
          modelUi.msgError(parseError(err))
        })
    },

    getTableData(index) {
      const start = (index - 1) * this.pageSize
      const end = start + this.pageSize
      const list = []
      const totalData = this.totalData || []
      for (let i = start; i < end; i++) {
        if (totalData.length > i) {
          const listItem = totalData[i]
          if (this.form.layoutId) {
            if (listItem.layoutId === this.form.layoutId) {
              listItem.checked = true
            }
          }
          list.push(totalData[i])
        }
      }
      this.tableData = list
    },

    handlePrior() {
      this.$emit('stepOne', this.srcForm)
      this.dialogVisible = false
    },

    handleNext() {
      let layoutGroupId = ''
      let layoutId = ''
      this.tableData.forEach(tableDataItem => {
        if (tableDataItem.checked) {
          layoutGroupId = tableDataItem.layoutStrategyId
          layoutId = tableDataItem.layoutId
        }
      })

      if (!layoutGroupId && !this.form.layoutGroupId) {
        this.$message.error(this.$t('taskManagerInfo.Please Select A Layout'))
        // modelUi.msgError(this.$t('taskManagerInfo.Please Select A Layout'))
        return
      }

      if (!this.form.pageNo) {
        this.$message.error(this.$t('taskManagerInfo.Please Select A Page'))
        // modelUi.msgError(this.$t('taskManagerInfo.Please Select A Page'))
        return
      }

      if (layoutGroupId) {
        this.form.layoutGroupId = layoutGroupId
        this.form.layoutId = layoutId
      }

      if (this.isForce) {
        this.form.force = 0
      } else {
        this.form.force = 1
      }

      this.$emit('layoutTwo', this.form)
      this.dialogVisible = false
    },
  },
}
</script>

<style lang="scss" scope>
.task-manger-switch-layout-one-dialog {
  .dialog-header {
    display: flex;
    align-items: center;
  }
  .dialog-index {
    width: 26px;
    height: 26px;
    line-height: 26px;
    font-size: 14px;
    background: linear-gradient(199deg, #29f19c 0%, #14cad5 100%);
    border-radius: 0px 20px 20px 20px;
    text-align: center;
    font-family: Impact;
    font-weight: 400;
    color: #ffffff;
  }

  .dialog-title {
    margin-left: 10px;
    font-size: 16px;
    font-family: Verdana;
    font-weight: 400;
    color: #35b871;
  }

  .el-dialog__header {
    background-color: #E9F9EF;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom: #D3F4E1;
    border-bottom: #D3F4E1;
  }

  .el-dialog__body {
    padding: 30px 20px !important;
  }

  .dialog-footer__layout__1 {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    // margin-bottom: -50px;
  }

  .search-condition {
    display: flex;
    align-items: center;
    // justify-content: center;
    // margin-top: 10px;
    margin-bottom: 16px;
  }

  .search-item {
    display: flex;
    align-items: center;
    margin: 0px 10px;
  }

  .search-item__span {
    color: #555555;
    font-size: 14px;
    display: inline-block;
    width: 60px;
    margin-right: 10px;
    text-align: right;
  }

  .search-item__red {
    color: #ff0000;
    font-size: 16px;
  }

  .search-item__select {
    width: 120px;
  }

  .search-item__input {
    width: 160px;
  }

  .search-item__button {
    background-color: #3461ff;
    border: 0px;
    color: #ffffff;
    width: 90px;
    height: 34px;
    font-size: 14px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .result-box {
    display: flex;
    flex-direction: column;
    margin-left: 70px;
  }

  .result-empty {
    display: flex;
    justify-content: center;
    color: #555555;
    font-size: 20px;
    line-height: 80px;
    height: 80px;
  }

  .result-list {
    display: flex;
    flex-wrap: wrap;
  }

  .result-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 8px 16px;
    width: calc(100% / 5 - 16px);
  }

  .result-name {
    width: 100%;
    text-align: center;
    color: #000;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .result-img {
    width: 100%;
    height: 90px;
    margin-bottom: 10px;
    background: #DDDDDD;
    border: 1px solid #DDDDDD;
    border-radius: 6px;
  }

  .result-status__checked {
    width: 20px;
    height: 20px;
    background: #ffffff;
    box-shadow: 0px 4px 5px 0px rgba(52, 97, 255, 0.18);
    border-radius: 13px;
    border: 5px solid #3461ff;
    margin-top: 10px;
  }

  .result-status__uncheck {
    width: 20px;
    height: 20px;
    background: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 13px;
    margin-top: 10px;
  }

  .result-page {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }

  .button-next {
    // border: 0px;
    width: 110px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .el-checkbox{
    margin-bottom: 0;
  }
}
</style>

<template>
  <el-dialog
    v-if="dialogVisible"
    :visible.sync="dialogVisible"
    :before-close="cancel"
    :close-on-click-modal="false"
    append-to-body
    width="820px"
    class="task-manger-switch-nfc-dialog"
    style="padding: 10px"
  >
    <div
      slot="title"
      class="dialog-header"
    >
      <div class="dialog-index">
        2
      </div>
      <span class="dialog-title"> {{ $t("taskManagerInfo.NFC") }}</span>
    </div>

    <div
      v-if="dialogVisible"
      v-loading="loading"
    >
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="135px"
        size="small"
      >
        <el-form-item
          :label="$t('taskManagerInfo.NFC')"
          prop="nfcInfo"
        >
          <el-select
            v-model="form.nfcInfo"
            :placeholder="$t('taskManagerInfo.Select a NFC')"
            @change="nfcChange"
            @visible-change="nfcVisibleChange"
            clearable
          >
            <el-option
              v-for="dict in nfcList"
              :key="dict.columnName"
              :label="dict.translateTextName"
              :value="dict.columnName"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer__nfc"
      >
        <el-button
          class="button-next gray-button"
          @click="handlePrior"
          size="small"
        > 
           {{ $t("taskManagerInfo.Prior") }}
        </el-button>
        <el-button
          class="button-next green-button"
          @click="handleNext"
          size="small"
        >
           {{ $t("taskManagerInfo.Next") }}
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import modelUi from '@/views/taskManagement/taskManger/utils/modelUi.js'

import { getScenario } from '@/libs/api/taskManger.js'

import { parseResp, parseError } from '@/views/taskManagement/taskManger/utils/requestUtil.js'

export default {
  components: {},
  data() {
    return {
      loading: false,
      dialogVisible: false,
      form: {},
      srcForm: {},
      nfcList: [],
      rules: {
        nfcInfo: { required: true, message:  this.$t("taskManagerInfo.Select a NFC"), trigger: 'change' },
      },
    }
  },
  methods: {
    openDialog(row) {
      this.srcForm = JSON.parse(JSON.stringify(row))
      this.form = row
      this.dialogVisible = true

      if (!this.nfcList || this.nfcList.length === 0) {
        this.getScenarioReq()
      }
    },
    cancel() {
      this.dialogVisible = false
    },

    nfcChange() {},

    nfcVisibleChange(val) {
      if (val && this.nfcList.length === 0) {
        this.getScenarioReq()
      }
    },

    getScenarioReq() {
      this.loading = true
      getScenario(this.form.scenario)
        .then(res => {
          this.loading = false
          const result = parseResp(res)
          if (result.success) {
            this.nfcList = result.data.fieldList || []
          } else {
            modelUi.msgError(result.message)
          }
        })
        .catch(err => {
          this.loading = false
          modelUi.msgError(parseError(err))
        })
    },

    handlePrior() {
      this.$emit('stepOne', this.srcForm)
      this.dialogVisible = false
    },

    handleNext() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('stepThree', this.form)
          this.dialogVisible = false
        }
      })
    },
  },
}
</script>

<style lang="scss" scope>
.task-manger-switch-nfc-dialog {
  .dialog-header {
    display: flex;
    align-items: center;
  }
  .dialog-index {
    width: 26px;
    height: 26px;
    line-height: 26px;
    font-size: 14px;
    background: linear-gradient(199deg, #29f19c 0%, #14cad5 100%);
    border-radius: 0px 20px 20px 20px;
    text-align: center;
    font-family: Impact;
    font-weight: 400;
    color: #ffffff;
  }

  .dialog-title {
    margin-left: 10px;
    font-size: 16px;
    font-family: Verdana;
    font-weight: 400;
    color: #35b871;
  }

  .el-dialog__header {
    background-color: #E9F9EF;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom: #D3F4E1;
  }

  .el-dialog__body {
    padding: 30px 20px !important;
  }

  .dialog-footer__nfc {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }

  .button-next {
    // border: 0px;
    width: 110px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
</style>

<template>
  <div class="template-scenarios">
    <div
      v-loading="loading"
      class="template-scenarios-group"
    >
      <div
        class="template-scenarios-group-children-item__group"
        style="margin-left: 0px"
      >
        <el-select
          v-model="taskTriggerCondition.condition"
          class="template-scenarios-group-children-item__group__select"
          :placeholder="$t('taskManagerInfo.Please Select')"
          @change="conditionTitleChange($event, taskTriggerCondition)"
          clearable
        >
          <el-option
            v-for="dict in conditionTitleList"
            :key="dict.dictValue"
            :label="dict.dictName"
            :value="dict.dictValue"
          />
        </el-select>
        <div class="template-scenarios-group-children-item__group__empty" />
        <img
          class="template-scenarios-group-children-item__group__add"
          src="@/assets/img/ic_add.png"
          @click="handleAddChild"
        >
        <img
          class="template-scenarios-group-children-item__group__tree"
          src="@/assets/img/ic_tree.png"
          @click="handleAddGroup"
        >
      </div>
      <div
        v-if="
          taskTriggerCondition.children &&
            taskTriggerCondition.children.length > 0
        "
        class="template-scenarios-group-children"
      >
        <div class="template-scenarios-group-children__line" />
        <div
          v-for="(parentItem, parentIndex) in taskTriggerCondition.children"
          :key="parentItem.itemId"
          class="
            template-scenarios-group-children-item template-scenarios-group
          "
        >
          <div
            v-if="parentItem.itemType === 'child'"
            class="template-scenarios-group-children-item__child"
          >
            <el-select
              v-model="parentItem.fieldName"
              :placeholder="$t('taskManagerInfo.Please Select')"
              class="template-scenarios-group-children-item__child__key"
              @change="fieldNameChange($event, parentItem)"
              @visible-change="fieldNameVisibleChange"
              clearable
            >
              <el-option
                v-for="dict in fieldList"
                :key="dict.fieldId"
                :label="dict.translateTextName"
                :value="dict.fieldId"
              />
            </el-select>
            <el-select
              v-model="parentItem.symbol"
              :placeholder="$t('taskManagerInfo.Please Select')"
              class="template-scenarios-group-children-item__child__operator"
              clearable
            >
              <el-option
                v-for="dict in parentItem.symbolList"
                :key="dict.dictValue"
                :label="dict.dictName"
                :value="dict.dictValue"
              />
            </el-select>

            <!--来自写入 文本-->
            <el-input
              v-model="parentItem.fieldValue"
              class="template-scenarios-group-children-item__child__value"
              clearable
            />

            <img
              class="template-scenarios-group-children-item__group__del"
              src="@/assets/img/ic_delete.png"
              @click="handleRemoveChild(parentIndex)"
            >
          </div>
          <div
            v-if="parentItem.itemType === 'group'"
            class="template-scenarios-group template-scenarios-group"
          >
            <div class="template-scenarios-group-children-item__group">
              <el-select
                v-model="parentItem.condition"
                :placeholder="$t('taskManagerInfo.Please Select')"
                class="template-scenarios-group-children-item__group__select"
                @change="conditionTitleChange($event, parentItem)"
                clearable
              >
                <el-option
                  v-for="dict in conditionTitleList"
                  :key="dict.dictValue"
                  :label="dict.dictName"
                  :value="dict.dictValue"
                />
              </el-select>
              <div
                class="template-scenarios-group-children-item__group__empty"
              />
              <img
                class="template-scenarios-group-children-item__group__del"
                src="@/assets/img/ic_delete.png"
                @click="handleRemoveChild(parentIndex)"
              >
              <img
                class="template-scenarios-group-children-item__group__add"
                src="@/assets/img/ic_add.png"
                @click="handleAddChildChild(parentItem)"
              >
            </div>
            <div
              v-if="parentItem.children && parentItem.children.length > 0"
              class="template-scenarios-group-children"
            >
              <div class="template-scenarios-group-children__line" />
              <div
                v-for="(childrenItem, childrenIndex) in parentItem.children"
                :key="childrenItem.itemId"
                class="
                  template-scenarios-group-children-item
                  template-scenarios-group
                "
              >
                <div class="template-scenarios-group-children-item__child">
                  <el-select
                    v-model="childrenItem.fieldName"
                    :placeholder="$t('taskManagerInfo.Please Select')"
                    class="template-scenarios-group-children-item__child__key"
                    @change="fieldNameChange($event, childrenItem)"
                    @visible-change="fieldNameVisibleChange"
                    clearable
                  >
                    <el-option
                      v-for="dict in fieldList"
                      :key="dict.fieldId"
                      :label="dict.translateTextName"
                      :value="dict.fieldId"
                    />
                  </el-select>
                  <el-select
                    v-model="childrenItem.symbol"
                    :placeholder="$t('taskManagerInfo.Please Select')"
                    class="
                      template-scenarios-group-children-item__child__operator
                    "
                    clearable
                  >
                    <el-option
                      v-for="dict in childrenItem.symbolList"
                      :key="dict.dictValue"
                      :label="dict.dictName"
                      :value="dict.dictValue"
                    />
                  </el-select>

                  <!--来自写入 文本-->
                  <el-input
                    v-if="
                      childrenItem.operateType === 'write' &&
                        fieldTypeIsText(childrenItem)
                    "
                    v-model="childrenItem.fieldValue"
                    class="template-scenarios-group-children-item__child__value"
                    clearable
                  />

                  <img
                    class="template-scenarios-group-children-item__group__del"
                    src="@/assets/img/ic_delete.png"
                    @click="handleRemoveChildChild(parentItem, childrenIndex)"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import modelUi from '@/views/taskManagement/taskManger/utils/modelUi.js'

import { getScenario } from '@/libs/api/taskManger.js'

import { getDateSymbolList, getNumberSymbolList, getTextSymbolList } from '@/views/taskManagement/taskManger/utils/dictUtil.js'

import { parseResp, parseError } from '@/views/taskManagement/taskManger/utils/requestUtil.js'

export default {
  props: {
    form: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      loading: false,
      taskTriggerCondition: {
        itemType: 'group',
        itemId: Date.now(),
        condition: '',
        children: [],
      },
      conditionTitleList: [
        {
          dictName: this.$t('taskManagerInfo.Meet any of the following conditions'),
          dictValue: 'or',
        },
        {
          dictName: this.$t('taskManagerInfo.All of the following conditions are meet'),
          dictValue: 'and',
        },
      ],
      symbolList: [],
      fieldList: [],
    }
  },

  created() {
    let taskTempCondition = this.form.taskTriggerCondition
    if (taskTempCondition) {
      taskTempCondition = JSON.parse(taskTempCondition)
      if (taskTempCondition.hasOwnProperty('and')) {
        const tempAndList = taskTempCondition.and
        const children = this.getChildren(tempAndList)
        this.taskTriggerCondition = {
          itemType: 'group',
          itemId: Date.now(),
          condition: 'and',
          children,
        }
      } else if (taskTempCondition.hasOwnProperty('or')) {
        const tempOrList = taskTempCondition.or
        const children = this.getChildren(tempOrList)
        this.taskTriggerCondition = {
          itemType: 'group',
          itemId: Date.now(),
          condition: 'or',
          children,
        }
      } else {
        this.taskTriggerCondition = {
          itemType: 'group',
          itemId: Date.now(),
          condition: '',
          children: [],
        }
      }
    } else {
      this.taskTriggerCondition = {
        itemType: 'group',
        itemId: Date.now(),
        condition: '',
        children: [],
      }
    }

    // this.symbolList = getSymbolList()
    this.getScenarioReq()
  },

  methods: {
    getChildren(list) {
      const children = []
      list.forEach(listItem => {
        if (listItem.hasOwnProperty('and')) {
          const tempAndList = listItem.and
          const children2 = this.getChildren(tempAndList)
          children.push({
            itemType: 'group',
            itemId: Date.now(),
            condition: 'and',
            children: children2,
          })
        } else if (listItem.hasOwnProperty('or')) {
          const tempOrList = listItem.or
          const children2 = this.getChildren(tempOrList)
          children.push({
            itemType: 'group',
            itemId: Date.now(),
            condition: 'or',
            children: children2,
          })
        } else {
          const { fieldName } = listItem
          const { symbol } = listItem
          const { isSystemField } = listItem
          const { fieldType } = listItem
          const { fieldValue } = listItem
          children.push({
            itemType: 'child',
            itemId: Date.now(),
            fieldName,
            symbol,
            isSystemField,
            fieldType,
            fieldValue,
            symbolList: this.getSymbolList(fieldType),
            operateType: isSystemField === 1 ? 'choose' : 'write', // write|choose
          })
        }
      })
      return children
    },

    getSymbolList(fieldType) {
      if (fieldType === 'text' || fieldType === 'url'
        || fieldType === 'radio' || fieldType === 'checkbox'
        || fieldType === 'img'
      ) {
        const list = getTextSymbolList()
        return list
      }
      if (fieldType === 'number') {
        const list = getNumberSymbolList()
        return list
      }
      if (fieldType === 'date') {
        const list = getDateSymbolList()
        return list
      }
      return []
    },

    // 条件标题改变
    conditionTitleChange(val, object) {},

    // 添加子
    handleAddChild() {
      this.taskTriggerCondition.children.push({
        itemType: 'child',
        itemId: Date.now(),
        fieldName: '',
        symbol: '',
        isSystemField: 0,
        fieldType: '',
        fieldValue: '',
        symbolList: [],
        operateType: 'choose', // write|choose
      })
    },

    // 添加组
    handleAddGroup() {
      this.taskTriggerCondition.children.push({
        itemType: 'group',
        itemId: Date.now(),
        condition: '',
        children: [],
      })
    },

    // fieldName改变
    fieldNameChange(val, objectItem) {
      // eslint-disable-next-line no-unused-vars
      const fieldItem = val
        ? this.fieldList.find(ele => ele.fieldId === val)
        : {}
      // eslint-disable-next-line no-param-reassign
      objectItem.fieldType = fieldItem.type
      objectItem.symbolList = this.getSymbolList(fieldItem.type)
    },

    // fieldName改变
    fieldNameVisibleChange(val) {
      if (val && this.fieldList.length === 0) {
        this.getScenarioReq()
      }
    },

    fieldValueChange(val) {},
    fieldValueVisibleChange(val) {
      if (val && this.fieldList.length === 0) {
        this.getScenarioReq()
      }
    },

    handleRemoveChild(index) {
      this.taskTriggerCondition.children.splice(index, 1)
    },

    handleAddChildChild(parentItem) {
      const children = parentItem.children || []
      children.push({
        itemType: 'child',
        itemId: Date.now(),
        fieldName: '',
        symbol: '',
        isSystemField: 0,
        fieldType: '',
        fieldValue: '',
        symbolList: [],
        operateType: 'choose', // write|choose
      })
    },

    handleRemoveChildChild(parentItem, childrenIndex) {
      parentItem.children.splice(childrenIndex, 1)
    },

    getScenarioReq() {
      this.loading = true
      getScenario(this.form.scenario)
        .then(res => {
          this.loading = false
          const result = parseResp(res)
          if (result.success) {
            this.fieldList = result.data.fieldList || []
          } else {
            modelUi.msgError(result.message)
          }
        })
        .catch(err => {
          this.loading = false
          modelUi.msgError(parseError(err))
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.template-scenarios-group {
  list-style: none;
  position: relative;
}

.template-scenarios-group:first-child:before {
  border-style: none none none none;
}

.template-scenarios-group:before {
  position: absolute;
  content: "";
  top: -0.01em;
  left: -30px;
  width: 25px;
  height: 50px;
  border-style: none none solid solid;
  border-width: 0.05em;
  border-color: #d3d3d3;
}

.template-scenarios-group:not(:last-child):after {
  position: absolute;
  content: "";
  top: 50px;
  left: -30px;
  bottom: 0;
  border-style: none none none solid;
  border-width: 0.05em;
  border-color: #d3d3d3;
}

.template-scenarios {
  display: flex;
  flex-direction: column;
  margin: 10px 50px;
}

.template-scenarios-group {
  display: flex;
  flex-direction: column;
}

.template-scenarios-group-children-item__group {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.template-scenarios-group-children-item__group__select {
  width: 350px;
}

.template-scenarios-group-children-item__group__empty {
  flex: 1;
}

.template-scenarios-group-children-item__group__del {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}

.template-scenarios-group-children-item__group__add {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}

.template-scenarios-group-children-item__group__tree {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}

.template-scenarios-group-children {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}

.template-scenarios-group-children__line {
  width: 0.05em;
  height: 20px;
  background: #d3d3d3;
  margin-left: -30px;
}

.template-scenarios-group-children-item__child {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.template-scenarios-group-children-item__child__key {
  width: 220px;
  margin-right: 10px;
}

.template-scenarios-group-children-item__child__operator {
  width: 220px;
  margin-right: 10px;
}

.template-scenarios-group-children-item__child__value {
  width: 220px;
  margin-right: 10px;
}

.template-scenarios-group-children-item__child__switch {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.template-scenarios-group-children-item__child__write {
  border: 1px solid #3461ff;
  height: 35px;
  width: 70px;
  color: #3461ff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.template-scenarios-group-children-item__child__choose {
  border: 1px solid #3461ff;
  height: 35px;
  width: 70px;
  color: #3461ff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.operate_type_checked {
  background-color: #3461ff;
  color: #ffffff;
}
</style>

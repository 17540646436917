<template>
  <el-dialog
    v-if="dialogVisible"
    :visible.sync="dialogVisible"
    :before-close="cancel"
    :close-on-click-modal="false"
    append-to-body
    width="820px"
    class="task-manger-switch-led-one-dialog"
    style="padding: 10px"
  >
    <div
      slot="title"
      class="dialog-header"
    >
      <div class="dialog-index">
        2
      </div>
      <span class="dialog-title">{{ $t("taskManagerInfo.LED Config") }}</span>
    </div>

    <div v-if="dialogVisible">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="155px"
        size="small"
      >
        <el-form-item
          :label="$t('taskManagerInfo.Strategy Name')"
          prop="strategyName"
        >
          <el-input
            v-model="form.strategyName"
            maxlength="100"
            clearable
          />
        </el-form-item>

        <el-form-item
          :label="$t('taskManagerInfo.Start Time')"
          prop="ledStartTime"
        >
          <el-date-picker
            v-model="form.ledStartTime"
            style="width: 100%"
            type="datetime"
            :picker-options="pickerOptions"
            placement="bottom-start"
            @change="handleKedStartTimeChange"
            clearable
          />
        </el-form-item>

        <el-form-item
          :label="$t('taskManagerInfo.Cycles')"
          prop="ledCycles"
        >
          <el-input
            v-model="form.ledCycles"
            type="number"
            @input="ledCyclesChange"
            clearable
          />
        </el-form-item>

        <el-form-item
          :label="$t('taskManagerInfo.Flash Color')"
          prop="ledFlashColor"
        >
          <el-select
            v-model="form.ledFlashColor"
            :placeholder="$t('taskManagerInfo.Select a Flash Color')"
            style="width: 100%"
            @change="flashColorChange"
            @visible-change="flashColorVisibleChange"
            clearable
          >
            <el-option
              v-for="dict in flashColorList"
              :key="dict.codeValue"
              :label="dict.codeName"
              :value="dict.codeValue"
            />
          </el-select>
        </el-form-item>

        <el-form-item
          :label="$t('taskManagerInfo.Flash Interva')"
          prop="flashInterval"
        >
          <el-input
            v-model="form.flashInterval"
            type="number"
            @input="flashIntervalChange"
            @blur="flashIntervalBlur"
            clearable
          />
        </el-form-item>

        <el-form-item
          :label="$t('taskManagerInfo.Flash Duration')"
          prop="flashDuration"
        >
          <el-input
            v-model="form.flashDuration"
            type="number"
            @input="flashDurationChange"
            clearable
          />
        </el-form-item>

        <el-form-item
          :label="$t('taskManagerInfo.Total Flashing Time')"
          prop="totalFlashingTime"
        >
          <el-input
            v-model="totalFlashingTimeDisplay"
            :disabled="true"
            clearable
          />
        </el-form-item>
        <div class="switch-led-flashtime-tip"
          >
          <!-- (Interval + Duration) * Cycles = Total Flashing Time -->
         {{ `( ${$t('taskManagerInfo.Interva')} + ${$t('taskManagerInfo.Duration')} ) * ${$t('taskManagerInfo.Cycles')} = ${$t('taskManagerInfo.Total Flashing Time')} `}} 
          </div
        >
      </el-form>
      <div slot="footer" class="dialog-footer__led">
        <el-button size="small" class="button-next gray-button" @click="handlePrior">
          {{ $t("taskManagerInfo.Prior") }}
        </el-button>
        <el-button size="small" class="button-next green-button" @click="handleNext">
          {{ $t("taskManagerInfo.Next") }}
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import modelUi from '@/views/taskManagement/taskManger/utils/modelUi.js'

import { getCodeList } from '@/libs/api/taskManger.js'

import {
  parseResp,
  parseError,
} from '@/views/taskManagement/taskManger/utils/requestUtil.js'

import { dateFormat } from '@/views/taskManagement/taskManger/utils/dateUtil.js'
import { materiallist } from '@/libs/api/scenario-config'

export default {
  components: {},
  data() {
    return {
      dialogVisible: false,
      pickerOptions: {
        disabledDate(time) {
          // 选择的日期大于当前日期
          return time.getTime() < Date.now() - 86400000
        },
      },
      totalFlashingTimeDisplay: '',
      form: {},
      srcForm: {},
      flashColorList: [],
      rules: {
        strategyName: {
          required: true,
          message: this.$t('taskManagerInfo.Input Strategy Name'),
          trigger: 'blur',
        },
        ledStartTime: {
          required: true,
          message: this.$t('taskManagerInfo.Input Start Time'),
          trigger: 'blur',
        },
        ledCycles: {
          required: true,
          message: this.$t('taskManagerInfo.Input Cycles'),
          trigger: 'blur',
        },
        ledFlashColor: {
          required: true,
          message: this.$t('taskManagerInfo.Select a Flash Color'),
          trigger: 'change',
        },
        flashInterval: {
          required: true,
          message: this.$t('taskManagerInfo.Input Flash Interva'),
          trigger: 'blur',
        },
        flashDuration: {
          required: true,
          message: this.$t('taskManagerInfo.Input Flash Duration'),
          trigger: 'blur',
        },
      },
    }
  },
  methods: {
    openDialog(row) {
      if (row && row.ledFlashColor) {
        row.ledFlashColor += ''
      }
      this.srcForm = JSON.parse(JSON.stringify(row))
      console.log('闪灯配置第一步',row)
      this.form = row
      this.dialogVisible = true
      this.getCodeListReq()
      this.handleTotalFlashingTime()
    },

    cancel() {
      this.dialogVisible = false
    },

    handleKedStartTimeChange() {
      this.form.startTime = null
      this.form.endTime = null
    },

    flashColorChange() {},

    flashColorVisibleChange(val) {
      if (val && this.flashColorList.length === 0) {
        this.getCodeListReq()
      }
    },

    getCodeListReq() {
      getCodeList(28)
        .then(res => {
          const result = parseResp(res)
          if (result.success) {
            this.flashColorList = result.data
          } else {
            modelUi.msgError(result.message)
          }
        })
        .catch(err => {
          modelUi.msgError(parseError(err))
        })
    },

    ledCyclesChange() {
      this.form.ledCycles = this.form.ledCycles.replace(/[^0-9]/g, '')
      this.form.ledCycles = parseInt(this.form.ledCycles)
      if (this.form.ledCycles <= 0) {
        this.form.ledCycles = 1
      }
      if (this.form.ledCycles > 65535) {
        this.form.ledCycles = 65535
      }
      this.handleTotalFlashingTime()
    },

    flashIntervalChange() {
      this.form.flashInterval = this.form.flashInterval.replace(/[^0-9]/g, '')
      this.form.flashInterval = parseInt(this.form.flashInterval)
      if (this.form.flashInterval <= 0) {
        this.form.flashInterval = 1
      }
      if (this.form.flashInterval > 255) {
        this.form.flashInterval = 255
      }
      this.handleTotalFlashingTime()
    },
    flashIntervalBlur(){
      if (this.form.flashInterval <= 100) {
        this.form.flashInterval = 100
      }
    },

    flashDurationChange() {
      this.form.flashDuration = this.form.flashDuration.replace(/[^0-9]/g, '')
      this.form.flashDuration = parseInt(this.form.flashDuration)
      if (this.form.flashDuration <= 0) {
        this.form.flashDuration = 1
      }
      if (this.form.flashDuration > 255) {
        this.form.flashDuration = 255
      }
      this.handleTotalFlashingTime()
    },

    handleTotalFlashingTime() {
      const ledCycles = this.form.ledCycles || 0
      const flashInterval = this.form.flashInterval || 0
      const flashDuration = this.form.flashDuration || 0
      const totalFlashingTime = (flashInterval + flashDuration) * ledCycles
      this.form.totalFlashingTime = totalFlashingTime
      this.totalFlashingTimeDisplay = this.getTotalFlashingTimeDisplay(totalFlashingTime)
    },

    getTotalFlashingTimeDisplay(totalFlashingTime) {
      // // 转换为式分秒
      // // eslint-disable-next-line radix
      // let h = '00'
      // if (totalFlashingTime >= 3600000) {
      //   // eslint-disable-next-line radix
      //   h = parseInt((totalFlashingTime / 1000 / 60 / 60) % 24)
      //   h = h < 10 ? `0${h}` : h
      // }
      // // eslint-disable-next-line no-mixed-operators
      // // eslint-disable-next-line radix
      // let m = '00'
      // if (totalFlashingTime >= 60000) {
      //   // eslint-disable-next-line radix
      //   m = parseInt((totalFlashingTime / 1000 / 60) % 60)
      //   m = m < 10 ? `0${m}` : m
      // }
      // // eslint-disable-next-line no-undef
      // // eslint-disable-next-line radix
      // let s = parseInt((totalFlashingTime / 1000) % 60)
      // s = s < 10 ? `0${s}` : s

      // eslint-disable-next-line radix
      let h = parseInt(
        (totalFlashingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
      ).toFixed(0)
      // eslint-disable-next-line radix
      let m = parseInt(
        (totalFlashingTime % (1000 * 60 * 60)) / (1000 * 60),
      ).toFixed(0)
      let s = ((totalFlashingTime % (1000 * 60)) / 1000).toFixed(0)
      if (h.length < 2) {
        h = `0${h}`
      }
      if (m.length < 2) {
        m = `0${m}`
      }
      if (s.length < 2) {
        s = `0${s}`
      }

      // 作为返回值返回
      return `${h}h${m}m${s}s`
    },

    handlePrior() {
      this.$emit('stepOne', this.srcForm)
      this.dialogVisible = false
    },

    handleNext() {
      this.$refs.form.validate(valid => {
        if (valid) {
          // this.$emit('ledTwo', this.form)
          this.$emit('stepThree', this.form)
          this.dialogVisible = false
        }
      })
    },
  },
}
</script>

<style lang="scss" scope>
.task-manger-switch-led-one-dialog {
  .dialog-header {
    display: flex;
    align-items: center;
  }
  .dialog-index {
    width: 26px;
    height: 26px;
    line-height: 26px;
    font-size: 14px;
    background: linear-gradient(199deg, #29f19c 0%, #14cad5 100%);
    border-radius: 0px 20px 20px 20px;
    text-align: center;
    font-family: Impact;
    font-weight: 400;
    color: #ffffff;
  }

  .dialog-title {
    margin-left: 10px;
    font-size: 16px;
    font-family: Verdana;
    font-weight: 400;
    color: #35b871;
  }

  .el-dialog__header {
    background-color: #E9F9EF;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom: #D3F4E1;
  }

  .el-dialog__body {
    padding: 30px 20px !important;
  }

  .dialog-footer__led {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }

  .switch-led-flashtime-tip {
    font-size: 14px;
    font-family: Verdana;
    color: #C0C4CC;
    margin-left: 155px;
    margin-top: -20px;
  }

  .button-next {
    // border: 0px;
    width: 110px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
</style>

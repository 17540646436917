<template>
  <el-dialog
    v-if="dialogVisible"
    :visible.sync="dialogVisible"
    :before-close="cancel"
    :close-on-click-modal="false"
    append-to-body
    width="820px"
    class="task-manger-switch-template-two-dialog"
    style="padding: 10px"
  >
    <div
      slot="title"
      class="dialog-header"
    >
      <div class="dialog-index">
        2
      </div>
      <span class="dialog-title">{{$t("taskManagerInfo.Select a Key (Interface Driven)")}}</span>
    </div>

    <div v-if="dialogVisible">
      <el-form ref="form" size="small" :model="form" :rules="rules">
        <el-form-item prop="templateType" style="text-align: center">
          <el-radio-group
            v-model="form.templateFiledType"
            @change="handleTemplateFiledTypeChange"
          >
            <el-radio
              v-for="item in templateFiledTypeList"
              :key="item.dictValue"
              :label="item.dictValue"
            >
              {{ item.dictName }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="templateFiledName" style="text-align: center">
          <el-input clearable v-model="form.templateFiledName" style="width: 300px" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer__template__2">
        <el-button class="button-next gray-button" size="small" @click="handlePrior"> {{ $t("taskManagerInfo.Prior") }}</el-button>
        <el-button class="button-next green-button" size="small" @click="handleNext"> {{ $t("taskManagerInfo.Next") }}</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import modelUi from "@/views/taskManagement/taskManger/utils/modelUi.js";

export default {
  components: {},
  data() {
    return {
      dialogVisible: false,
      templateFiledTypeList: [
        {
          dictValue: 1,
          dictName: this.$t("taskManagerInfo.Template Code") ,
        },
        {
          dictValue: 2,
          dictName: this.$t("taskManagerInfo.Template Name"),
        },
      ],
      srcForm: {},
      form: {},
      rules: {
        templateFiledName: {
          required: true,
          message: this.$t("taskManagerInfo.Input TemplateFiledName"),
          trigger: "blur",
        },
      },
    };
  },
  methods: {
    openDialog(row) {
      this.form = row;
      this.srcForm = JSON.parse(JSON.stringify(row));
      this.dialogVisible = true;
    },
    cancel() {
      this.dialogVisible = false;
    },
    handleTemplateFiledTypeChange() {},

    handlePrior() {
      this.$emit("templateOne", this.srcForm);
      this.dialogVisible = false;
    },
    handleNext() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit("stepThree", this.form);
          this.dialogVisible = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scope>
.task-manger-switch-template-two-dialog {
   .dialog-header {
    display: flex;
    align-items: center;
  }
  .dialog-index {
    width: 26px;
    height: 26px;
    line-height: 26px;
    font-size: 14px;
    background: linear-gradient(199deg, #29f19c 0%, #14cad5 100%);
    border-radius: 0px 20px 20px 20px;
    text-align: center;
    font-family: Impact;
    font-weight: 400;
    color: #ffffff;
  }

  .dialog-title {
    margin-left: 10px;
    font-size: 16px;
    font-family: Verdana;
    font-weight: 400;
    color: #35b871;
  }

  .el-dialog__header {
    background-color: #E9F9EF;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom: #D3F4E1;
  }

  .el-dialog__body {
    padding: 30px 20px !important;
  }

  .dialog-footer__template__2 {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }

  .button-next {
    // border: 0px;
    width: 110px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
</style>

<template>
  <el-dialog
    v-if="dialogVisible"
    :visible.sync="dialogVisible"
    :before-close="cancel"
    :close-on-click-modal="false"
    append-to-body
    width="820px"
    class="task-manger-switch-page-two-dialog"
    style="padding: 10px"
  >
    <div
      slot="title"
      class="dialog-header"
    >
      <div class="dialog-index">
        2
      </div>
      <span class="dialog-title">{{$t('taskManagerInfo.Switch Page')}}</span>
    </div>
    <div v-if="dialogVisible">
      <el-form ref="form" size="small" :model="form" :rules="rules" label-width="155px">
        <el-form-item :label="$t('taskManagerInfo.Strategy Name')" prop="strategyName">
          <el-input clearable v-model="form.strategyName" :disabled="true" />
        </el-form-item>

        <el-form-item :label="$t('taskManagerInfo.Start Time')" prop="ledStartTime">
          <el-input clearable v-model="ledStartTime" :disabled="true" />
        </el-form-item>

        <el-form-item :label="$t('taskManagerInfo.Stay time(m)')" prop="stayTime">
          <el-input clearable v-model="form.stayTime" :disabled="true" />
        </el-form-item>

        <el-form-item :label="$t('taskManagerInfo.Switch Pages')" prop="switchPages">
          <el-select
            v-model="form.switchPages"
            style="width: 100%"
            :disabled="true"
            clearable
          >
            <el-option
              v-for="dict in pageNumberList"
              :key="dict.dictValue"
              :label="dict.dictName"
              :value="dict.dictValue"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer__led">
        <el-button class="button-next gray-button" size="small" @click="handlePrior">{{$t('taskManagerInfo.Prior')}}</el-button>
        <el-button class="button-next green-button" size="small" @click="handleNext">{{$t('taskManagerInfo.Next')}}</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import modelUi from "@/views/taskManagement/taskManger/utils/modelUi.js";

import { dateFormat } from "@/views/taskManagement/taskManger/utils/dateUtil.js";

import typeUtil from "@/views/taskManagement/taskManger/utils/typeUtil.js";
import { pageNumberList } from '@/libs/enum'
export default {
  components: {},
  data() {
    return {
      pageNumberList,
      dialogVisible: false,
      ledStartTime: "",
      srcForm: {},
      form: {},
      rules: {
        strategyName: {
          required: true,
          message: this.$t('taskManagerInfo.Input Strategy Name'),
          trigger: "blur",
        },
        ledStartTime: {
          required: true,
          message: this.$t('taskManagerInfo.Select Start Time'),
          trigger: "blur",
        },
        stayTime: {
          required: true,
          message: this.$t('taskManagerInfo.Input Stay time'),
          trigger: "blur",
        },
        switchPages: {
          required: true,
          message:  this.$t('taskManagerInfo.Select a Page'),
          trigger: "change",
        },
      },
    };
  },
  methods: {
    openDialog(row) {
      console.log('第二步骤的row',row)
      this.srcForm = JSON.parse(JSON.stringify(row));
      this.form = row;
      if (this.form.ledStartTime) {
        if (typeUtil.isString(this.form.ledStartTime)) {
          this.ledStartTime = dateFormat(
            new Date(this.form.ledStartTime),
            "yyyy-MM-dd HH:mm:ss"
          );
        }
        if (typeUtil.isDate(this.form.ledStartTime)) {
          this.ledStartTime = dateFormat(this.form.ledStartTime, "yyyy-MM-dd HH:mm:ss");
        }
      } else {
        this.ledStartTime = null;
      }
      this.dialogVisible = true;
    },

    cancel() {
      this.dialogVisible = false;
    },

    handlePrior() {
      this.$emit("pageOne", this.srcForm);
      this.dialogVisible = false;
    },

    handleNext() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit("stepThree", this.form);
          this.dialogVisible = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scope>
.task-manger-switch-page-two-dialog {
  .dialog-header {
    display: flex;
    align-items: center;
  }
  .dialog-index {
    width: 26px;
    height: 26px;
    line-height: 26px;
    font-size: 14px;
    background: linear-gradient(199deg, #29f19c 0%, #14cad5 100%);
    border-radius: 0px 20px 20px 20px;
    text-align: center;
    font-family: Impact;
    font-weight: 400;
    color: #ffffff;
  }

  .dialog-title {
    margin-left: 10px;
    font-size: 16px;
    font-family: Verdana;
    font-weight: 400;
    color: #35b871;
  }

  .el-dialog__header {
    background-color: #E9F9EF;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom: #D3F4E1;
  }

  .el-dialog__body {
    padding: 30px 20px !important;
  }

  .dialog-footer__led {
    display: flex;
    justify-content: center;
  }

  .button-next {
    // border: 0px;
    width: 110px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
</style>


 
<template>
  <div class="card">
    <slot></slot>
  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {}
  },
  methods: {
  },
}
</script>
<style style lang="scss" scoped>
  .card {
    padding:15px;
    box-shadow: none;
  }
</style>


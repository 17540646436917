/* eslint-disable no-empty */
// eslint-disable-next-line import/prefer-default-export
import i18n from '@/libs/i18n/index'

export function checkTriggerConditionVerify(objectData) {
  console.log('objectData',objectData)
  if (!objectData) {
    // return 'Scenarios is null'
    return i18n.t('taskManagerInfo.Scenarios is null')
  }
  if (
    (!objectData.or || objectData.or.length === 0)
    && (!objectData.and || objectData.and.length === 0)
  ) {
    // return 'Scenarios Condition is Null'
    return i18n.t('taskManagerInfo.Scenarios is null')
  }
  let result = ''
  if (objectData.or && objectData.or.length > 0) {
    // eslint-disable-next-line consistent-return
    objectData.or.forEach(orItem => {
      // eslint-disable-next-line no-prototype-builtins
      if (orItem.hasOwnProperty('or')) {
        if (!orItem.or || orItem.or.length === 0) {
          // result = 'Exit Scenarios Condition is Null'
          result = i18n.t('taskManagerInfo.Scenarios is null')
        }
        // eslint-disable-next-line consistent-return
        orItem.or.forEach(orChildrenItem => {
          const { fieldName } = orChildrenItem
          const { symbol } = orChildrenItem
          const { fieldValue } = orChildrenItem
          if (!fieldName || fieldName === null) {
            // result = 'fieldName is Null'
            result =  i18n.t('taskManagerInfo.Scenarios is null')
          }
          if (!symbol || symbol === null) {
            // result = 'symbol is Null'
            result =  i18n.t('taskManagerInfo.Scenarios is null')
          }
          if ((!fieldValue && fieldValue !== 0) || fieldValue === null) {
            if (symbol === 7 || symbol === 8 || symbol === 9 || symbol === 10) {
            } else {
              // result = 'fieldValue is Null'
              result =  i18n.t('taskManagerInfo.Scenarios is null')
            }
          }
        })
        // eslint-disable-next-line no-prototype-builtins
      } else if (orItem.hasOwnProperty('and')) {
        if (!orItem.and || orItem.and.length === 0) {
          // result = 'Exit Scenarios Condition is Null'
          result =  i18n.t('taskManagerInfo.Scenarios is null')
        }
        // eslint-disable-next-line consistent-return
        orItem.and.forEach(andChildrenItem => {
          const { fieldName } = andChildrenItem
          const { symbol } = andChildrenItem
          const { fieldValue } = andChildrenItem
          if (!fieldName || fieldName === null) {
            // result = 'fieldName is Null'
            result =  i18n.t('taskManagerInfo.Scenarios is null')
          }
          if (!symbol || symbol === null) {
            // result = 'symbol is Null'
            result =  i18n.t('taskManagerInfo.Scenarios is null')
          }
          if ((!fieldValue && fieldValue !== 0) || fieldValue === null) {
            if (symbol === 7 || symbol === 8 || symbol === 9 || symbol === 10) {
            } else {
              // result = 'fieldValue is Null'
              result =  i18n.t('taskManagerInfo.Scenarios is null')
            }
          }
        })
      } else {
        const { fieldName } = orItem
        const { symbol } = orItem
        const { fieldValue } = orItem
        if (!fieldName || fieldName === null) {
          // result = 'fieldName is Null'
          result =  i18n.t('taskManagerInfo.Scenarios is null')
        }
        if (!symbol || symbol === null) {
          // result = 'symbol is Null'
          result =  i18n.t('taskManagerInfo.Scenarios is null')
        }
        if ((!fieldValue && fieldValue!== 0) || fieldValue === null) {
          if (symbol === 7 || symbol === 8 || symbol === 9 || symbol === 10) {
          } else {
            // result = 'fieldValue is Null'
            result =  i18n.t('taskManagerInfo.Scenarios is null')
          }
        }
      }
    })
  }
  if (objectData.and && objectData.and.length > 0) {
    // eslint-disable-next-line consistent-return
    objectData.and.forEach(orItem => {
      // eslint-disable-next-line no-prototype-builtins
      if (orItem.hasOwnProperty('or')) {
        if (!orItem.or || orItem.or.length === 0) {
          // result = 'Exit Scenarios Condition is Null'
          result =  i18n.t('taskManagerInfo.Scenarios is null')
        }
        // eslint-disable-next-line consistent-return
        orItem.or.forEach(orChildrenItem => {
          const { fieldName } = orChildrenItem
          const { symbol } = orChildrenItem
          const { fieldValue } = orChildrenItem
          if (!fieldName || fieldName === null) {
            // result = 'fieldName is Null'
            result = i18n.t('taskManagerInfo.Scenarios is null')
          }
          if (!symbol || symbol === null) {
            // result = 'symbol is Null'
            result = i18n.t('taskManagerInfo.Scenarios is null')
          }
          if ((!fieldValue && fieldValue !== 0) || fieldValue === null) {
            if (symbol === 7 || symbol === 8 || symbol === 9 || symbol === 10) {
            } else {
              // result = 'fieldValue is Null'
              result = i18n.t('taskManagerInfo.Scenarios is null')
            }
          }
        })
        // eslint-disable-next-line no-prototype-builtins
      } else if (orItem.hasOwnProperty('and')) {
        if (!orItem.and || orItem.and.length === 0) {
          // result = 'Exit Scenarios Condition is Null'
          result = i18n.t('taskManagerInfo.Scenarios is null')
        }
        // eslint-disable-next-line consistent-return
        orItem.and.forEach(andChildrenItem => {
          const { fieldName } = andChildrenItem
          const { symbol } = andChildrenItem
          const { fieldValue } = andChildrenItem
          if (!fieldName || fieldName === null) {
            // result = 'fieldName is Null'
            result = i18n.t('taskManagerInfo.Scenarios is null')
          }
          if (!symbol || symbol === null) {
            // result = 'symbol is Null'
            result = i18n.t('taskManagerInfo.Scenarios is null')
          }
          if ((!fieldValue && fieldValue!== 0) || fieldValue === null) {
            if (symbol === 7 || symbol === 8 || symbol === 9 || symbol === 10) {
            } else {
              // result = 'fieldValue is Null'
              result = i18n.t('taskManagerInfo.Scenarios is null')
            }
          }
        })
      } else {
        const { fieldName } = orItem
        const { symbol } = orItem
        const { fieldValue } = orItem
        if (!fieldName || fieldName === null) {
          // result = 'fieldName is Null'
          result = i18n.t('taskManagerInfo.Scenarios is null')
        }
        if (!symbol || symbol === null) {
          // result = 'symbol is Null'
          result = i18n.t('dtaskManagerInfo.Scenarios is null')
        }
        if ((!fieldValue && fieldValue !==0) || fieldValue === null) {
          if (symbol === 7 || symbol === 8 || symbol === 9 || symbol === 10) {
          } else {
            // result = 'fieldValue is Null'
            result = i18n.t('taskManagerInfo.Scenarios is null')
          }
        }
      }
    })
  }
  return result
}

/* eslint-disable import/extensions */
/* eslint-disable import/prefer-default-export */
import request from '@/libs/axios.js'
import config from "../../config/index"
const { baseUrl } = config;

//  获取模版类型列表
export function getTemplateTypeList(params) {
  const url = `${baseUrl}template/type/v1/getList`

  return request({
    url,
    method: 'POST',
    data: params,
  })
}

// 模版类型逻辑删除
export function deleteTemplateType(templateTypeId) {
  const url = `${baseUrl}template/type/v1/delete`

  return request({
    url,
    method: 'POST',
    data: {
      templateTypeId,
    },
  })
}

export function updateTemplateType(templateTypeId, templateTypeName) {
  const url = `${baseUrl}template/type/v1/update`

  return request({
    url,
    method: 'POST',
    data: {
      templateTypeId,
      templateTypeName,
    },
  })
}

export function createTemplateType(params) {
  const url = `${baseUrl}template/type/v1/create`

  return request({
    url,
    method: 'POST',
    data: params,
  })
}

<template>
  <div class="confirm-dialog">
    <el-dialog
      :title="$t(confirmObj.type)"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
    >
      <div class="cont">
        <!-- <span class="icon"><i class="el-icon-warning-outline"></i></span> -->
        <span class="text">{{$t(confirmObj.content)}}</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <span class="cancel" @click="handleClose('cancel')">{{$t(confirmObj.cancelText)}}</span>
        <span class="confirm" v-loading="confirmObj.loading ? confirmObj.loading : false" @click="handleClose('confirm')">{{$t(confirmObj.confirmText)}}</span>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name:'ConfirmDialog',
  data() {
    return {}
  },
  props:{
    dialogVisible: {
        type: Boolean,
        required: true
    },
    confirmObj: {
      type: Object,
      default: null,
    }
  },
  methods: {
    handleClose(val) {
      this.$emit('handleClose', val)
    }
  }
}
</script>
<style lang="scss" scoped>
.confirm-dialog ::v-deep{
  color:#35B871;
    .el-dialog__wrapper{
      display: flex;
      justify-content: center;
      align-items: center;
    }
     .el-dialog{
      height: 300px;
      border-radius: 6px;
      width: 400px;
      margin-top: 0 !important;
      margin: 0 !important;
    }
    ::v-deep .el-dialog__body{
      padding: 0 !important;
    }
    .cont{
      text-align: center;
      padding-top: 40px;
      img{
        width: 46px;
        height: 46px;
        display: block;
        margin: 0 auto;
      }
      .icon {
        display: flex;
        justify-content: center;
        color: #FF9600;
      }
      ::v-deep .el-icon-warning-outline {
        font-size: 46px;
      }
      .text{
        margin-top: 16px;
        display: inline-block;
        // color: #FF9600;
      }
    }
    .cancel, .confirm{
      cursor: pointer;
      width: 50%;
      position: absolute;
      bottom: 0;
      height: 50px;
      line-height: 50px;
      border-top: 1px solid #E6E6E6;
      vertical-align: center;

    }
    .cancel{
      border-right: 1px solid #E6E6E6;
      left: 0;
      color:#333333;
    }
    .confirm{
      right: 0;
      color: #35B871;
    }
}
</style>
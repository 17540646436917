<template>
  <el-dialog
    v-if="dialogVisible"
    :visible.sync="dialogVisible"
    :before-close="cancel"
    :close-on-click-modal="false"
    append-to-body
    width="820px"
    class="task-manger-switch-layout-two-dialog"
    style="padding: 10px"
  >
    <div
      slot="title"
      class="dialog-header"
    >
      <div class="dialog-index">
        2
      </div>
      <span class="dialog-title">{{$t("taskManagerInfo.Select a Key (Interface Driven)")}}</span>
    </div>

    <div v-if="dialogVisible">
      <el-form ref="form" :model="form" :rules="rules" size="small">
        <el-form-item prop="templateType" style="text-align: center">
          <div class="search-body">
            <div v-if="isChecked" class="search-item">
              <div class="result-status__checked" />
              <span class="search-item__span"> {{ $t("taskManagerInfo.Layout Name") }}</span>
            </div>
            <div v-else class="search-item">
              <div class="result-status__uncheck" />
              <span class="search-item__span"> {{ $t("taskManagerInfo.Layout Name") }}</span>
            </div>
          </div>
        </el-form-item>
        <el-form-item prop="layoutName" style="text-align: center">
          <el-input
            clearable
            v-model="form.layoutName"
            class="search-item__input"
            :placeholder="$t('taskManagerInfo.Input layout name')"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer__layout__2">
        <el-button class="button-next gray-button" size="small" @click="handlePrior">  {{ $t("taskManagerInfo.Prior") }}</el-button>
        <el-button class="button-next green-button"  size="small" @click="handleNext">  {{ $t("taskManagerInfo.Next") }}</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import modelUi from "@/views/taskManagement/taskManger/utils/modelUi.js";

export default {
  components: {},
  data() {
    return {
      dialogVisible: false,
      isChecked: true,
      srcForm: {},
      form: {},
      rules: {
        layoutName: {
          required: true,
          message: this.$t('taskManagerInfo.Input layout name'),
          trigger: "blur",
        },
      },
    };
  },
  methods: {
    openDialog(row) {
      this.srcForm = JSON.parse(JSON.stringify(row));
      this.form = row;
      this.dialogVisible = true;
    },
    cancel() {
      this.dialogVisible = false;
    },
    handlePrior() {
      this.$emit("layoutOne", this.srcForm);
      this.dialogVisible = false;
    },
    handleNext() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit("stepThree", this.form);
          this.dialogVisible = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scope>
.task-manger-switch-layout-two-dialog {
  .dialog-header {
    display: flex;
    align-items: center;
  }
  .dialog-index {
    width: 26px;
    height: 26px;
    line-height: 26px;
    font-size: 14px;
    background: linear-gradient(199deg, #29f19c 0%, #14cad5 100%);
    border-radius: 0px 20px 20px 20px;
    text-align: center;
    font-family: Impact;
    font-weight: 400;
    color: #ffffff;
  }

  .dialog-title {
    margin-left: 10px;
    font-size: 16px;
    font-family: Verdana;
    font-weight: 400;
    color: #35b871;
  }

  .el-dialog__header {
    background-color: #E9F9EF;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom: #D3F4E1;
  }

  .el-dialog__body {
    padding: 30px 20px !important;
  }

  .dialog-footer__layout__2 {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }

  .search-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .search-item {
    display: flex;
    align-items: center;
    height: 60px;
    margin: 0px 10px;
  }

  .search-item__span {
    color: #555555;
    font-size: 16px;
  }

  .search-item__input {
    width: 300px;
  }

  .result-status__checked {
    width: 20px;
    height: 20px;
    background: #ffffff;
    border-radius: 13px;
    border: 5px solid #3461ff;
    margin: 0px;
    margin-right: 10px;
  }

  .result-status__uncheck {
    width: 20px;
    height: 20px;
    background: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 13px;
    margin: 0px;
    margin-right: 10px;
  }

  .button-next {
    // border: 0px;
    width: 110px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
</style>

<template>
  <div
    v-loading="loading"
    class="page-task-manger-list"
  >
    <div class="public-breadcrumb">
      <router-link style="color: #6e6b7b" to="/">{{
        $t("devices.Welcome")
      }}</router-link> > 
      <span style="color:#3461FF">{{ $t('Task Management') }}</span>
    </div>
    <topbar>
      <div class="handleBtn">
        <div>
          <el-button
            type="primary"
            size="small"
            @click="handleAddTask"
          >
            <i class="el-icon-plus" />
            {{$t("button.New")}}
          </el-button>
          <el-button
            type="primary"
            size="small"
            
            @click="handleExecuteSchedule"
          >
            <i class="el-icon-caret-right" />
            {{$t("button.Execute")}}
          </el-button>
          <!-- :disabled="selectionList.length !== 1" -->
          <el-button
            type="danger"
            size="small"
            @click="handleBatchDelete"
          >
            <i class="el-icon-delete" />
             {{$t("button.Batch Delete")}}
          </el-button>
           <!-- :disabled="selectionList.length === 0" -->
        </div>
      </div>
    </topbar>
    <div class="table_set">
      <div class="select_filter">
        <div>
          <span class="text">{{$t("taskManagerInfo.Records")}}: {{ pageParams.total }} </span>
          <el-select
            v-model="pageParams.storeQueryType"
            placeholder=""
            @change="getScheduleListReq"
            size="small"
          >
             <el-option
              v-for="(item, i) in storeTypeList()"
              :key="i"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </div>
      </div>
     <div class="table-content">
      <el-table
        :data="tableData"
        style="width: 100%; margin-bottom: 20px"
        :header-cell-style="{ 'text-align': 'center' }"
        row-key="id"
        @sort-change="handleSortChange"
        @selection-change="handleSelectionChange">
        <!-- <el-table-column width="55">
          <el-table-column type="selection" />
        </el-table-column> -->
         <el-table-column align="center" type="selection">
            <el-table-column
              align="center"
              width="55"
              type="selection"
              style="display: none"
            >
            </el-table-column>
          </el-table-column>
        <el-table-column
          :label="$t('taskManagerInfo.Store Name')"
          prop="storeName"
        >
          <el-table-column
            prop="storeName"
            min-width="150"
            align="center"
          >
            <template
              slot="header"
              slot-scope="scope"
            >
              <el-input
                v-model="pageParams.storeName"
                size="mini"
                placeholder=""
                @input="getScheduleListReq"
                clearable
              />
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column
          :label="$t('taskManagerInfo.Task Name')"
          sortable="custom"
          prop="task_name"
        >
          <el-table-column
            prop="taskName"
            min-width="150"
            align="center"
          >
            <template
              slot="header"
              slot-scope="scope"
            >
              <el-input
                v-model="pageParams.taskName"
                size="mini"
                placeholder=""
                @input="getScheduleListReq"
                clearable
              />
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column
          :label="$t('taskManagerInfo.Task Type')"
          sortable="custom"
          prop="task_type"
        >
          <el-table-column
            prop="taskType"
            min-width="220"
            align="center"
          >
            <template
              slot="header"
              slot-scope="scope"
            >
              <el-select
                v-model="pageParams.taskType"
                class="task_el_date_picker"
                size="mini"
                clearable
                placeholder=""
                @change="getScheduleListReq"
              >
                <el-option
                  v-for="dict in taskTypeList"
                  :key="dict.codeId"
                  :label="dict.codeName"
                  :value="dict.codeId"
                />
              </el-select>
            </template>
            <template slot-scope="scope">
              <span
                v-if="scope.row.taskType === 1207"
                class="table-cell__task-type"
              >
                <img
                  class="table-cell__task-type-icon"
                  src="@/assets/img/ic_switch_temp.png"
                >
                <span class="table-cell__task-type-name">
                  {{scope.row.taskTypeName}}
                </span>
              </span>
              <span
                v-if="scope.row.taskType === 1206"
                class="table-cell__task-type"
              >
                <img
                  class="table-cell__task-type-icon"
                  src="@/assets/img/ic_layout.png"
                >
                <span class="table-cell__task-type-name">  {{scope.row.taskTypeName}} </span>
              </span>
              <span
                v-if="scope.row.taskType === 1203"
                class="table-cell__task-type"
              >
                <img
                  class="table-cell__task-type-icon"
                  src="@/assets/img/ic_led.png"
                >
                <span class="table-cell__task-type-name">  {{scope.row.taskTypeName}}</span>
              </span>
              <span
                v-if="scope.row.taskType === 1204"
                class="table-cell__task-type"
              >
                <img
                  class="table-cell__task-type-icon"
                  src="@/assets/img/ic_switch_page.png"
                >
                <span class="table-cell__task-type-name"> {{scope.row.taskTypeName}}</span>
              </span>
              <span
                v-if="scope.row.taskType === 1205"
                class="table-cell__task-type"
              >
                <img
                  class="table-cell__task-type-icon"
                  src="@/assets/img/ic_nfc.png"
                >
                <span class="table-cell__task-type-name">  {{scope.row.taskTypeName}}</span>
              </span>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column
          :label="$t('taskManagerInfo.Execute Order')"
          sortable="custom"
          prop="execute_order"
        >
          <el-table-column
            prop="executeOrder"
            min-width="150"
            align="center"
          >
            <template
              slot="header"
              slot-scope="scope"
            >
              <el-input
                v-model="pageParams.executeOrder"
                size="mini"
                placeholder=""
                @input="getScheduleListReq"
                clearable
              />
            </template>
            <template slot-scope="scope">
              <template v-if="editTaskScheduleId !== scope.row.taskScheduleId">
                <span >{{scope.row.executeOrder }}</span>
                <span class="table-icon"  @click="handleEditExecuteOrder(scope.row)" :title="$t('button.Edit')">
                  <i class="el-icon-edit-outline"></i>
                </span>
              </template>
              <template v-else>
                <div style="display: flex;">
                  <el-input
                    v-model="editExecuteOrder"
                    @change="handleModifyExecuteOrder"
                    size="mini"
                    placeholder=""
                    clearable
                    autofocus
                  />
                  <!-- <el-input-number size="small" v-model="editExecuteOrder" controls-position="right" @change="handleModifyExecuteOrder" :min="0" ></el-input-number> -->
                  <span class="table-icon"  @click="handleModifyExecuteOrder(scope.row)" :title="$t('button.Edit')">
                    <i class="el-icon-circle-check"></i>
                  </span>
                  <span class="table-icon"  @click="closeEditExecuteOrder" :title="$t('button.Edit')">
                    <i class="el-icon-circle-close"></i>
                  </span>
                </div>
              </template>
              
              
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column
          :label="$t('taskManagerInfo.Begin Time')"
          sortable="custom"
          prop="start_time"
        >
          <el-table-column
            prop="startTime"
            min-width="220"
            align="center"
          >
            <template
              slot="header"
              slot-scope="scope"
            >
              <el-date-picker
                v-model="pageParams.startTime"
                class="task_el_date_picker"
                size="mini"
                type="daterange"
                range-separator="-"
                @change="getScheduleListReq"
                placement="bottom-start"
                clearable
              />
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column
          :label="$t('taskManagerInfo.End Time')"
          sortable="custom"
          prop="end_time"
        >
          <el-table-column
            prop="endTime"
            min-width="220"
            align="center"
          >
            <template
              slot="header"
              slot-scope="scope"
            >
              <el-date-picker
                v-model="pageParams.endTime"
                class="task_el_date_picker"
                size="mini"
                type="daterange"
                range-separator="-"
                @change="getScheduleListReq"
                placement="bottom-start"
                clearable
              />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          :label="$t('taskManagerInfo.Enable')"
          sortable="custom"
          prop="is_effect"
        >
          <el-table-column
            prop="enable"
            width="120px"
            align="center"
          >
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.enable"
                :active-value="1"
                :inactive-value="0"
                @change="updateTaskStateReq(scope.row, scope.row.enable)"
              />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          :label="$t('taskManagerInfo.Action')"
          fixed="right"
          width="150"
        >
          <el-table-column width="150">
            <template slot-scope="{ row }">
              <!-- <span
                class="icon"
                @click="handleTaskHistory(row)"
              >
                <icon-svg name="history" />
              </span> -->
              <div style="display:flex;align-items:center;justify-content: center;">
                

                <img
                  style="width:20px;height:auto; margin-right:5px; cursor:pointer"
                  src="@/assets/img/ESL_history_icon.png"
                  @click="handleTaskHistory(row)"
                  :title="$t('taskManagerInfo.Task Update Records')"
                >

                <span class="table-icon"  @click="handleEditTask(row)" :title="$t('button.Edit')">
                  <i class="el-icon-edit-outline"></i>
                </span>

                <!-- <span
                  class="icon"
                  @click="handleEditTask(row)"
                >
                  <icon-svg name="edit" />
                </span> -->

                <span class="table-icon"  @click="handleCopyTask(row)" :title="$t('button.Copy')">
                  <i class="el-icon-document-copy"></i>
                </span>
                <!-- <span
                  class="icon"
                  @click="handleCopyTask(row)"
                >
                  <icon-svg name="Copy" />
                </span> -->
              </div>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
     </div>
     <pagination :tableInfo="pageParams" @changePage="changePage" />
    </div>
    <taskHistoryDialog ref="taskHistoryDialogRef" />

    <stepOneDialog
      ref="stepOneDialogRef"
      @stepTwo="handleGoStepTwo"
    />
    <!--模板相关-->
    <switchTemplateOneDialog
      ref="switchTemplateOneDialogRef"
      @stepOne="handleGoStepOne"
      @templateTwo="handleGoTemplateTwo"
    />
    <switchTemplateTwoDialog
      ref="switchTemplateTwoDialogRef"
      @templateOne="handleGoTemplateOne"
      @stepThree="handleGoStepThree"
    />
    <!--布局相关-->
    <switchLayoutOneDialog
      ref="switchLayoutOneDialogRef"
      @stepOne="handleGoStepOne"
      @layoutTwo="handleGoLayoutTwo"
    />
    <switchLayoutTwoDialog
      ref="switchLayoutTwoDialogRef"
      @layoutOne="handleGoLayoutOne"
      @stepThree="handleGoStepThree"
    />

    <!--页面相关 @pageTwo="handleGoPageTwo"-->
    <switchPageOneDialog
      ref="switchPageOneDialogRef"
      @stepOne="handleGoStepOne"
      @stepThree="handleGoStepThree"
    />
    <switchPageTwoDialog
      ref="switchPageTwoDialogRef"
      @pageOne="handleGoPageOne"
      @stepThree="handleGoStepThree"
    />
    <!--LED相关 @ledTwo="handleGoLedTwo"-->
    <switchLedOneDialog
      ref="switchLedOneDialogRef"
      @stepOne="handleGoStepOne"
      @stepThree="handleGoStepThree"
    />
    <switchLedTwoDialog
      ref="switchLedTwoDialogRef"
      @ledOne="handleGoLedOne"
      @stepThree="handleGoStepThree"
    />

    <!--NFC相关-->
    <switchNfcDialog
      ref="switchNfcDialogRef"
      @stepOne="handleGoStepOne"
      @stepThree="handleGoStepThree"
    />

    <stepThree01Dialog
      ref="stepThree01DialogRef"
      @stepTwo="handleGoStepTwo_2"
      @submit="handleSubmit"
      @stepFour="handleGoStepFour"
    />
    <stepThree2Dialog
      ref="stepThree2DialogRef"
      @stepTwo="handleGoStepTwo_2"
      @submit="handleSubmit"
    />
    <stepThree3Dialog
      ref="stepThree3DialogRef"
      @stepTwo="handleGoStepTwo_2"
      @submit="handleSubmit"
    />
    <stepFourDialog
      ref="stepFourDialogRef"
      @stepThree="handleGoStepThree"
      @submit="handleSubmit"
    />

    <confirm-dialog
      :dialogVisible="isVisibleConfirmDialog"
      @handleClose="handelConfirmDialog"
      :confirmObj="confirmObj"
    >
    </confirm-dialog>
  </div>
</template>

<script>
import {
  getScheduleList,
  batchDeleteSchedule,
  executeSchedule,
  copySchedule,
  getSchedule,
  updateTaskState,
  getCodeList,
  taskExecutionSequence
} from '@/libs/api/taskManger.js'

import topbar from '@/components/ui-topbar/index.vue'

import modelUi from './utils/modelUi.js'

import { dateFormat } from './utils/dateUtil.js'

import taskHistoryDialog from './dialog/taskHistoryDialog.vue'
import stepOneDialog from './dialog/stepOneDialog.vue'
import switchTemplateOneDialog from './dialog/switchTemplateOneDialog.vue'
import switchTemplateTwoDialog from './dialog/switchTemplateTwoDialog.vue'
import switchLayoutOneDialog from './dialog/switchLayoutOneDialog.vue'
import switchLayoutTwoDialog from './dialog/switchLayoutTwoDialog.vue'
import switchLedOneDialog from './dialog/switchLedOneDialog.vue'
import switchLedTwoDialog from './dialog/switchLedTwoDialog.vue'
import switchPageOneDialog from './dialog/switchPageOneDialog.vue'
import switchPageTwoDialog from './dialog/switchPageTwoDialog.vue'
import switchNfcDialog from './dialog/switchNfcDialog.vue'
import stepThree01Dialog from './dialog/stepThree01Dialog.vue'
import stepThree2Dialog from './dialog/stepThree2Dialog.vue'
import stepThree3Dialog from './dialog/stepThree3Dialog.vue'
import stepFourDialog from './dialog/stepFourDialog.vue'

import { parseResp, parseError } from './utils/requestUtil.js'

import { getSelectedStoreId } from './utils/cacheUtil.js'

import { commonStore } from '@/store/global-select-store'
import pagination from "@/components/pagination";
import ConfirmDialog from "@/components/confirm-dialog/index.vue";
import {
  storeTypeList
} from "@/libs/enum";
export default {
  components: {
    topbar,
    taskHistoryDialog,
    stepOneDialog,
    switchTemplateOneDialog,
    switchTemplateTwoDialog,
    switchLayoutOneDialog,
    switchLayoutTwoDialog,
    switchLedOneDialog,
    switchLedTwoDialog,
    switchPageOneDialog,
    switchPageTwoDialog,
    switchNfcDialog,
    stepThree01Dialog,
    stepThree2Dialog,
    stepThree3Dialog,
    stepFourDialog,
    pagination,
    ConfirmDialog
  },
  data() {
    return {
      storeTypeList,
      commonStore,
      loading: false,
      selectionList: [],
      pageParams: {
        current: 1,
        size: 10,
        storeName: '',
        storeId: getSelectedStoreId(),
        storeQueryType: 2,
        taskName: '',
        taskType: '',
        startTime: null,
        endTime: null,
        total: 0,
        orders:[]
      },
      tableData: [],
      selectIds: [],
      columns: [],
      taskTypeList: [
        {
          codeId: 1207,
          codeName: this.$t("taskManagerInfo.DiologOne.Switch Template"),
        },
        {
          codeId: 1206,
          codeName: this.$t("taskManagerInfo.DiologOne.Switch Layout"),
        },
        {
          codeId: 1203,
          codeName: this.$t("taskManagerInfo.DiologOne.LED"),
        },
        {
          codeId: 1204,
          codeName: this.$t("taskManagerInfo.DiologOne.Switch Page"),
        },
        {
          codeId: 1205,
          codeName: this.$t("taskManagerInfo.DiologOne.NFC"),
        },
      ],
      isVisibleConfirmDialog: false,
      confirmObj: {
        type: this.$t("taskManagerInfo.Tips"),
        cancelText: this.$t("taskManagerInfo.Cancel"),
        confirmText: this.$t("taskManagerInfo.Sure"),
        content: this.$t("taskManagerInfo.Are you sure to delete the selected task？"),
      },
      editTaskScheduleId:'',
      editExecuteOrder:''
    }
  },
  created() {
    commonStore.setInjectCallBack(this.getScheduleListReq)
    this.getScheduleListReq()
    this.getTaskTypeListReq()
  },
  methods: {
    handelConfirmDialog(val) {
      const { currentConfirmType } = this;
      if (val === "confirm") {
       if(currentConfirmType === 'batchDeleteScheduleReq') {
        this.doBatchDeleteScheduleReq();
       }

       if(currentConfirmType === 'executeScheduleReq') {
        this.doExecuteScheduleReq();
       }
      } else {
        this.isVisibleConfirmDialog = false;
      }
    },
    // 获取任务类型--下拉选项内容
    getTaskTypeListReq() {
      getCodeList(12)
        .then((res) => {
          const result = parseResp(res);
          if (result.success) {
            let typeList = result.data;
            typeList = typeList.filter(item => item.codeHelp === 'taskSchedule')
            console.log('typeList',typeList)
            this.taskTypeList =typeList;
          } else {
            modelUi.msgError(result.message);
          }
        })
        .catch((err) => {
          modelUi.msgError(parseError(err));
        });
    },
    // do doExecuteScheduleReq
    async doExecuteScheduleReq() {
      const { selectionList } = this
      let taskScheduleIds = selectionList.map(it => it.taskScheduleId)
      try {
        this.loading = true
        const { data } = await executeSchedule({taskScheduleIds});
        if (data.state === true) {
          this.$message.success(this.$t('taskManagerInfo.Operation success'));
          this.isVisibleConfirmDialog = false;
          this.getScheduleListReq();
        } else {
          this.$message.error(data.message);
        }
        this.loading = false
      } catch (err) {
        const { message } = err;
        this.$message.error(message);
        this.loading = false
      }
    },
    // do doBatchDeleteScheduleReq
    async doBatchDeleteScheduleReq() {
      const { selectionList } = this
      let taskScheduleIds = selectionList.map(it => it.taskScheduleId)
      taskScheduleIds = taskScheduleIds && taskScheduleIds.length ? taskScheduleIds.join(','): ''
      try {
        this.loading = true
        const { data } = await batchDeleteSchedule(taskScheduleIds);
        if (data.state === true) {
          this.$message.success(this.$t('taskManagerInfo.Operation success'));
          this.isVisibleConfirmDialog = false;
          this.getScheduleListReq();
        } else {
          this.$message.error(data.message);
        }
        this.loading = false
      } catch (err) {
        const { message } = err;
        this.$message.error(message);
        this.loading = false
      }

    },
    changePage() {
      this.getScheduleListReq();
    },
    //  批量删除任务
    handleBatchDelete() {
      this.batchDeleteScheduleReq()
    },
    // 手动执行一个任务
    handleExecuteSchedule() {
      this.executeScheduleReq()
    },

    handleSelectionChange(selection) {
      this.selectionList = selection
    },
    // 处理排序
    handleSortChange(column, prop, order) {
      console.log('表格排序',column, prop, order)
      let arr = []
      if(column.order){
        let str1= column.order === "ascending" ? "asc" : "desc";
        str1 = str1 + ':'+ column.prop;
        arr.push(str1)
      } else {
        arr = []
      }
      
      this.pageParams.orders = arr;
      this.getScheduleListReq()
    },
    // 点击编辑执行顺序
    handleEditExecuteOrder(row){
      this.editTaskScheduleId = row.taskScheduleId
      this.editExecuteOrder = row.executeOrder
    },
    // 修改执行顺序
    async handleModifyExecuteOrder(row){
      // const {taskScheduleId} = row
      const executeOrder = this.editExecuteOrder
      const taskScheduleId = this.editTaskScheduleId
      console.log(row) 
      try {
        this.loading = true
        const { data } = await taskExecutionSequence({executeOrder,taskScheduleId});
        if (data.state === true) {
          this.$message.success(this.$t('taskManagerInfo.Operation success'));
          this.editTaskScheduleId = ''
          this.getScheduleListReq();
        } else {
          this.$message.error(data.message);
        }
        this.loading = false
      } catch (err) {
        const { message } = err;
        this.$message.error(message);
        this.loading = false
      }
    },
    // 取消编辑执行顺序
    closeEditExecuteOrder(){
      this.editTaskScheduleId = ''
      this.editExecuteOrder = ''
    },

    // 更新任务
    handleTaskHistory(row) {
      console.log('row==', row)
      this.$refs.taskHistoryDialogRef.openDialog(row.taskScheduleId)
    },

    // 新建
    handleAddTask() {
      const row = {
        storeId: getSelectedStoreId(),
        taskName: '',
        taskType: '',
        scenario: '',
        executionMode: '',
        enable: 0,
        templateType: '', // 模板类型
        pageNo: 1,
        force: 1,
        templateFiledType: 1, // 获取模板的方式(1：templateCode，2：templateName)
        templateFiledName: '', // 获取模板的字段名称
        layoutType: 0, //  布局类型
        layoutGroupId: '', // 布局组id（一个布局组可能存在多个尺寸的布局）
        layoutId: '',
        layoutName: '', // 布局名称
        strategyName: '', // LED策略
        ledStartTime: null, // LED任务开始执行时间
        ledCycles: '', // led闪灯频率
        ledFlashColor: '', // led闪灯颜色
        flashInterval: '', // 闪灯频率，即多久闪一次
        flashDuration: '', // 灯亮一次持续时长
        totalFlashingTime: '', // 闪灯总时长
        stayTime: '', // 切页停留时间（单位为m）
        switchPages: '', // 切页，页码（多个以‘,’逗号隔开）
        nfcInfo: '', // nfc相关信息
        taskTriggerCondition: null,
        eslType: '',
        startTime: null,
        cycle: 0,
        frequency: '',
        endTime: null,
        systemField: 1,
      }
      this.handleGoStepOne(row)
    },

    handleEditTask(row) {
      console.log('row==', row)
      const { taskScheduleId } = row
      this.loading = true
      getSchedule(taskScheduleId)
        .then(res => {
          this.loading = false
          const result = parseResp(res)
          if (result.success) {
            const data = result.data || {}
            if (data.ledStartTime) {
              data.ledStartTime = new Date(data.ledStartTime)
            }
            if (data.startTime) {
              data.startTime = new Date(data.startTime)
            }
            if (data.endTime) {
              data.endTime = new Date(data.endTime)
            }
            this.handleGoStepOne(data)
          } else {
            modelUi.msgError(result.message)
          }
        })
        .catch(err => {
          this.loading = false
          modelUi.msgError(parseError(err))
        })
    },

    // 复制任务
    handleCopyTask(row) {
      console.log('row==', row)
      const { taskScheduleId } = row
      this.loading = true
      copySchedule(taskScheduleId)
        .then(res => {
          this.loading = false
          const result = parseResp(res)
          if (result.success) {
            const data = result.data || {}
            data.taskScheduleId = ''
            if (data.ledStartTime) {
              data.ledStartTime = new Date(data.ledStartTime)
            }
            if (data.startTime) {
              data.startTime = new Date(data.startTime)
            }
            if (data.endTime) {
              data.endTime = new Date(data.endTime)
            }
            this.handleGoStepOne(data)
          } else {
            modelUi.msgError(result.message)
          }
        })
        .catch(err => {
          this.loading = false
          modelUi.msgError(parseError(err))
        })
    },

    // 第一步
    handleGoStepOne(row) {
      console.log('row==', row)
      this.$refs.stepOneDialogRef.openDialog(row)
    },

    // 第二步
    handleGoStepTwo(row) {
      console.log('row==', row)
      if (row.taskType === 1207) {
        // 切换模板
        this.handleGoTemplateOne(row)
      }
      if (row.taskType === 1206) {
        // 切换布局
        this.handleGoLayoutOne(row)
      }
      if (row.taskType === 1203) {
        // LED
        this.handleGoLedOne(row)
      }
      if (row.taskType === 1204) {
        // 切换页面
        this.handleGoPageOne(row)
      }
      if (row.taskType === 1205) {
        // NFC
        this.handleGoNFC(row)
      }
    },

    handleGoStepTwo_2(row) {
      console.log('row==', row)
      if (row.taskType === 1207) {
        // 切换模板
        if (row.executionMode === 3) {
          this.handleGoTemplateTwo(row)
        } else {
          this.handleGoTemplateOne(row)
        }
      }
      if (row.taskType === 1206) {
        // 切换布局
        if (row.executionMode === 3) {
          this.handleGoLayoutTwo(row)
        } else {
          this.handleGoLayoutOne(row)
        }
      }
      if (row.taskType === 1203) {
        // LED
        // this.handleGoLedTwo(row)
        this.handleGoLedOne(row)
      }
      if (row.taskType === 1204) {
        // 切换页面
        // this.handleGoPageTwo(row)
        this.handleGoPageOne(row)
      }
      if (row.taskType === 1205) {
        // NFC
        this.handleGoNFC(row)
      }
    },

    // 模板1
    handleGoTemplateOne(row) {
      console.log('row==', row)
      this.$refs.switchTemplateOneDialogRef.openDialog(row)
    },

    // 模板2
    handleGoTemplateTwo(row) {
      console.log('row==', row)
      if (row.executionMode === 3) {
        this.$refs.switchTemplateTwoDialogRef.openDialog(row)
      } else {
        this.handleGoStepThree(row)
      }
    },

    // 布局1
    handleGoLayoutOne(row) {
      console.log('row==', row)
      this.$refs.switchLayoutOneDialogRef.openDialog(row)
    },

    // 布局2
    handleGoLayoutTwo(row) {
      console.log('row==', row)
      if (row.executionMode === 3) {
        this.$refs.switchLayoutTwoDialogRef.openDialog(row)
      } else {
        this.handleGoStepThree(row)
      }
    },

    // 页面1
    handleGoPageOne(row) {
      console.log('row==', row)
      this.$refs.switchPageOneDialogRef.openDialog(row)
    },

    // 页面2
    handleGoPageTwo(row) {
      console.log('row==', row)
      this.$refs.switchPageTwoDialogRef.openDialog(row)
    },

    // LED1
    handleGoLedOne(row) {
      console.log('row==', row)
      this.$refs.switchLedOneDialogRef.openDialog(row)
    },

    // LED2
    handleGoLedTwo(row) {
      console.log('row==', row)
      this.$refs.switchLedTwoDialogRef.openDialog(row)
    },

    // NFC
    handleGoNFC(row) {
      console.log('row==', row)
      this.$refs.switchNfcDialogRef.openDialog(row)
    },

    // 第三步
    handleGoStepThree(row) {
      console.log('row==', row)
      const { executionMode } = row
      if (executionMode === 0 || executionMode === 1) {
        // 直接执行/按计划执行
        this.$refs.stepThree01DialogRef.openDialog(row)
      }
      if (executionMode === 2) {
        // 数据驱动
        this.$refs.stepThree2DialogRef.openDialog(row)
      }
      if (executionMode === 3) {
        // 接口驱动
        this.$refs.stepThree3DialogRef.openDialog(row)
      }
    },

    // 第四步
    handleGoStepFour(row) {
      console.log('row==', row)
      this.$refs.stepFourDialogRef.openDialog(row)
    },

    // 提交
    handleSubmit() {
      this.getScheduleListReq()
    },

    // 获取任务列表
    getScheduleListReq() {
      const storeId = getSelectedStoreId()
      const { storeQueryType } = this.pageParams
      const { taskType } = this.pageParams
      const { taskName } = this.pageParams
      const { startTime } = this.pageParams
      const { storeName } = this.pageParams
      const { executeOrder, orders } = this.pageParams
      let beginTimeStart = null
      let beginTimeEnd = null
      if (startTime && startTime.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        beginTimeStart = new Date(dateFormat(startTime[0], 'yyyy-MM-dd'))
        // eslint-disable-next-line prefer-destructuring
        beginTimeEnd = new Date(dateFormat(startTime[1], 'yyyy-MM-dd'))
      }

      const { endTime } = this.pageParams
      let endTimeStart = null
      let endTimeEnd = null
      if (endTime && endTime.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        endTimeStart = new Date(dateFormat(endTime[0], 'yyyy-MM-dd'))
        // eslint-disable-next-line prefer-destructuring
        endTimeEnd = new Date(dateFormat(endTime[1], 'yyyy-MM-dd'))
      }

      const isEffect = '0'
      const { current } = this.pageParams
      const { size } = this.pageParams
      // const orders = []

      getScheduleList({
        storeId,
        storeQueryType,
        taskType,
        taskName,
        beginTimeStart,
        beginTimeEnd,
        endTimeStart,
        endTimeEnd,
        isEffect,
        current,
        size,
        orders,
        storeName,
        executeOrder
      })
        .then(res => {
          const result = parseResp(res)
          if (result.success) {
            this.tableData = result.data.records || []
            this.pageParams.current = result.data.current
            this.pageParams.total = result.data.total
          } else {
            modelUi.msgError(result.message)
          }
        })
        .catch(err => {
          modelUi.msgError(parseError(err))
        })
    },

    // 批量删除任务
    batchDeleteScheduleReq() {
      const { selectionList } = this
      // const taskScheduleIds = selectionList.map(it => it.taskScheduleId)
      // let sss = ''
      if (!selectionList.length) {
        modelUi.msgError(this.$t("taskManagerInfo.No data selected"))
        return
      }
      // taskScheduleIds.forEach(taskScheduleId => {
      //   if (sss) {
      //     sss += `,${taskScheduleId}`
      //   } else {
      //     sss = taskScheduleId
      //   }
      // })
      this.isVisibleConfirmDialog  = true;
      this.currentConfirmType = "batchDeleteScheduleReq"
      this.confirmObj.content = "taskManagerInfo.Are you sure to delete the selected task？"
      /*const that = this
      modelUi.confirm(this.$t('taskManagerInfo.Are you sure to delete the selected task？')).then(() => {
        that.loading = true
        return batchDeleteSchedule(sss)
      }).then(res => {
        that.loading = false
        const result = parseResp(res)
        if (result.success) {
          modelUi.msgSuccess(this.$t('taskManagerInfo.Operation success'))
          that.getScheduleListReq()
        } else {
          modelUi.msgError(result.message)
        }
      }).catch(err => {
        that.loading = false
        if (err === 'cancel') {
          return
        }
        modelUi.msgError(parseError(err))
      })*/
    },

    // 手动执行一个任务
    executeScheduleReq() {
      const { selectionList } = this
      // let taskScheduleIds = selectionList.map(it => it.taskScheduleId)
      // if (!taskScheduleIds.length) {
      //   modelUi.msgError(this.$t("taskManagerInfo.Please select an execution task"))
      //   return
      // }
      if(!selectionList.length) {
        this.$message.error(this.$t("taskManagerInfo.Please select an execution task"))
        return
      }
      this.isVisibleConfirmDialog  = true;
      this.currentConfirmType = "executeScheduleReq"
      this.confirmObj.content = this.$t("taskManagerInfo.Are you sure to execute the selected task？")
      /*this.loading = true
      executeSchedule({taskScheduleIds})
        .then(res => {
          this.loading = false
          const result = parseResp(res)
          if (result.success) {
            modelUi.msgSuccess(this.$t('taskManagerInfo.Operation success'))
            this.getScheduleListReq()
          } else {
            modelUi.msgError(result.message)
          }
        })
        .catch(err => {
          this.loading = false
          modelUi.msgError(parseError(err))
        })*/
    },

    // 更新任务状态
    updateTaskStateReq(row, enable) {
      // 1 开启 生效  0 关闭 不生效
      const { taskScheduleId } = row
      this.loading = false
      let msg = ''
      if (enable === 0) {
        msg = this.$t('taskManagerInfo.Task closed successfully')
      } else {
        msg = this.$t('taskManagerInfo.Task opened successfully')
      }
      updateTaskState({
        taskScheduleId,
        enable,
      }).then(res => {
        this.loading = false
        const result = parseResp(res)
        if (result.success) {
          modelUi.msgSuccess(msg)
          this.getScheduleListReq()
        } else {
          modelUi.msgError(result.message)
        }
      })
        .catch(err => {
          this.loading = false
          modelUi.msgError(parseError(err))
        })
    },
  },
  computed: {
    language() {
      return this.$store.state.appConfig.language;
    },
  },
  watch: {
    language(val) {
      if (val) this.getScheduleListReq();
    },
  },
}
</script>
<style lang="scss" scoped>
.handleBtn {
  display: flex;
  justify-content: space-between;
  .el-button {
    border: none;
  }
}
::v-deep .hideClass {
  visibility: hidden;
}
.icon {
  padding: 6px;
  cursor: pointer;
  .svg-icon {
    width: 23px;
    height: 23px;
  }
}


.text {
  padding: 0 10px 0 15px;
}
.table_set {
  padding: 15px 0;
  background-color: white;
  .select_filter {
    padding-bottom: 15px;
    .table_label_size {
      width: 160px;
      font-size: 16px;
    }
    .table_select {
      width: 214px;
    }
  }
  .icon {
    padding: 6px;
    cursor: pointer;
    .svg-icon {
      width: 23px;
      height: 23px;
    }
  }
  .b_pag {
    padding: 0 35px;
  }
  .tb_checkbox {
    vertical-align: middle;
  }
}
.table-content ::v-deep {
  .el-table
    .el-table__header-wrapper
    .el-table__header
    .is-group
    tr:nth-of-type(2)
    th:nth-of-type(1)
    .cell {
    display: none;
  }
}
.table-set::-webkit-scrollbar {
  width: 1600px;
}

.page-task-manger-list {
  .task_el_input {
  }

  .task_el_date_picker {
    width: 200px;
  }

  .table-cell__task-type {
    display: inline-block;
    background-color: #f1f1f1;
    border-radius: 14px;
    padding: 2px 10px;
  }

  .table-cell__task-type-icon {
    width: 16px;
    height: 16px;
  }

  .table-cell__task-type-name {
    color: #555555;
    font-size: 14px;
    margin-left: 5px;
  }

  .table-icon  {
    padding:6px;
    cursor: pointer;
    font-size: 18px;
    color: #000;
  }
}
</style>

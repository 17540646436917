export default {

	/**
	 * 精确判断数据是否是 Object 类型
	 * @param {Any} val 要判断的数据
	 * @returns {Boolean} true：是；false：不是；
	 */
	isObject(val) {
		return Object.prototype.toString.call(val) === '[object Object]' && val !== null && val !== undefined;
	},

	/**
	 * 判断数据是否是 Array 类型
	 * @param {Any} val 要判断的数据
	 * @returns {Boolean} true：是；false：不是；
	 */
	isArray(val) {
		return Object.prototype.toString.call(val) === '[object Array]';
	},

	/**
	 * 判断数据是否是 String 类型
	 * @param {Any} val 要判断的数据
	 * @returns {Boolean} true：是；false：不是；
	 */
	isString(val) {
		return Object.prototype.toString.call(val) === '[object String]';
	},

	/**
	 * 精确判断数据是否是 Date 类型
	 * @param {Any} val 要判断的数据
	 * @returns {Boolean} true：是；false：不是；
	 */
	isDate(val) {
		return Object.prototype.toString.call(val) === '[object Date]';
	},

	/**
	 * 精确判断数据是否是 Function 类型
	 * @param {Any} val 要判断的数据
	 * @returns {Boolean} true：是；false：不是；
	 */
	isFunction(val) {
		return Object.prototype.toString.call(val) === '[object Function]';
	},

	/**
	 * 精确判断数据是否是 Number 类型
	 * @param {Any} val 要判断的数据
	 * @returns {Boolean} true：是；false：不是；
	 */
	isNumber(val) {
		return Object.prototype.toString.call(val) === '[object Number]';
	},

	/**
	 * 精确判断数据是否是 Boolean 类型
	 * @param {Any} val 要判断的数据
	 * @returns {Boolean} true：是；false：不是；
	 */
	isBoolean(val) {
		return Object.prototype.toString.call(val) === '[object Boolean]';
	}
}

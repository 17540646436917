<template>
  <div
    v-loading="loading"
    class="template-esl-type"
  >
    <div class="template-esl-type-all">
      <img
        v-if="allChecked"
        class="template-esl-type-item__icon"
        src="@/assets/img/ic_checked.png"
        @click="handleAllCheck(false)"
      >
      <img
        v-else
        class="template-esl-type-item__icon"
        src="@/assets/img/ic_uncheck.png"
        @click="handleAllCheck(true)"
      >
      <span class="template-esl-type-item__text">{{$t('taskManagerInfo.All')}}</span>
    </div>
    <div
      v-if="tagTypeList && tagTypeList.length > 0"
      class="template-esl-type-list"
    >
      <div
        v-for="tagTypeItem in tagTypeList"
        :key="tagTypeItem.tagTypeCodeId"
        class="template-esl-type-item"
      >
        <img
          v-if="tagTypeItem.checked"
          class="template-esl-type-item__icon"
          src="@/assets/img/ic_checked.png"
          @click="handleCheck(tagTypeItem, false)"
        >
        <img
          v-else
          class="template-esl-type-item__icon"
          src="@/assets/img/ic_uncheck.png"
          @click="handleCheck(tagTypeItem, true)"
        >
        <span class="template-esl-type-item__text">{{ tagTypeItem.typeName }}</span>
      </div>
    </div>
  </div>
</template>

<script>

import modelUi from '@/views/taskManagement/taskManger/utils/modelUi.js'

import { getAllTagType } from '@/libs/api/taskManger.js'

import { parseResp, parseError } from '@/views/taskManagement/taskManger/utils/requestUtil.js'

export default {
  props: {
    form: {
      type: Object,
      default() {
        return {}
      },
    },
  },

  data() {
    return {
      loading: false,
      allChecked: false,
      tagTypeList: [],
    }
  },

  created() {
    this.getAllTagTypeReq()
  },

  methods: {
    handleAllCheck(allChecked) {
      this.allChecked = allChecked
      const tagTypeList = this.tagTypeList || []
      if (tagTypeList.length > 0) {
        tagTypeList.forEach(tagTypeItem => {
          // eslint-disable-next-line no-param-reassign
          tagTypeItem.checked = allChecked
        })
      }
      this.tagTypeList = tagTypeList
    },

    handleCheck(tagTypeItem, checked) {
      // eslint-disable-next-line no-param-reassign
      tagTypeItem.checked = checked

      let flag = true
      // eslint-disable-next-line no-shadow
      this.tagTypeList.forEach(tagTypeItem => {
        // eslint-disable-next-line no-param-reassign
        if (!tagTypeItem.checked) {
          flag = false
        }
      })
      this.allChecked = flag
    },

    getAllTagTypeReq() {
      let eslType = this.form.eslType || ''
      if(typeof eslType === 'number') eslType = eslType.toString()
      const ss = eslType ? eslType.split(',') : ''
      const objKey = {}

      if(ss) {
        ss.forEach(ssItem => {
          objKey[ssItem] = ssItem
        })
      }
      
      this.loading = true

      const merchantId = localStorage.getItem('merchantId') || ''

      getAllTagType(merchantId, '').then(res => {
        this.loading = false
        const result = parseResp(res)
        if (result.success) {
          const List = result.data || []
          List.forEach(listItem => {
          // eslint-disable-next-line no-param-reassign
            listItem.checked = objKey[listItem.tagTypeCodeId]
          })
          this.tagTypeList = List
        } else {
          // modelUi.msgError(result.message)
          this.$message.error(result.message)
        }
      }).catch(err => {
        this.loading = false
        modelUi.msgError(parseError(err))
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.template-esl-type {
  margin: 10px 50px;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
}

.template-esl-type-all {
  height: 40px;
  background: #f1f4ff;
  display: flex;
  align-items: center;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.template-esl-type-list {
  display: flex;
  flex-direction: column;
}

.template-esl-type-item {
  height: 40px;
  background: #ffffff;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d1d1d1;
}

.template-esl-type-item__icon {
  margin-left: 30px;
  width: 20px;
  height: 20px;
}

.template-esl-type-item__text {
  margin-left: 20px;
  color: #555;
  font-size: 14px;
}

.template-esl-type-item:last-child {
  border-bottom: 0px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

</style>

/* eslint-disable import/prefer-default-export */
import i18n from '@/libs/i18n/index'
export function getSymbolList() {
  const list = [
    {
      dictValue: 1,
      dictName: '=',
    },
    {
      dictValue: 2,
      dictName: '>',
    },
    {
      dictValue: 3,
      dictName: '>=',
    },
    {
      dictValue: 4,
      dictName: '<',
    },
    {
      dictValue: 5,
      dictName: '<=',
    },
    {
      dictValue: 6,
      dictName: i18n.t('taskManagerInfo.Contains'),
    },
    {
      dictValue: 7,
      dictName: i18n.t('taskManagerInfo.Is empty'),
    },
    {
      dictValue: 8,
      dictName: i18n.t('taskManagerInfo.Is not empty'),
    },
    {
      dictValue: 9,
      dictName: i18n.t('taskManagerInfo.Is null'),
    },
    {
      dictValue: 10,
      dictName: i18n.t('taskManagerInfo.Is not null'),
    },
  ]
  return list
}

export function getDateSymbolList() {
  const list = [
    {
      dictValue: 1,
      dictName: i18n.t('taskManagerInfo.Equal'),
    },
    {
      dictValue: 4,
      dictName: i18n.t('taskManagerInfo.Less than'),
    },
    {
      dictValue: 5,
      dictName: i18n.t('taskManagerInfo.Less equal'),
    },
    {
      dictValue: 2,
      dictName: i18n.t('taskManagerInfo.Greater than'),
    },
    {
      dictValue: 3,
      dictName: i18n.t('taskManagerInfo.Greater equal'),
    },
    {
      dictValue: 11,
      dictName: i18n.t('taskManagerInfo.Unequal'),
    },
    {
      dictValue: 9,
      dictName: i18n.t('taskManagerInfo.Is null'),
    },
    {
      dictValue: 10,
      dictName: i18n.t('taskManagerInfo.Is not null'),
    }
  ]
  return list
}

export function getNumberSymbolList() {
  const list = [
    {
      dictValue: 1,
      dictName: i18n.t('taskManagerInfo.Equal'),
    },
    {
      dictValue: 4,
      dictName: i18n.t('taskManagerInfo.Less than'),
    },
    {
      dictValue: 5,
      dictName: i18n.t('taskManagerInfo.Less equal'),
    },
    {
      dictValue: 2,
      dictName: i18n.t('taskManagerInfo.Greater than'),
    },
    {
      dictValue: 3,
      dictName: i18n.t('taskManagerInfo.Greater equal'),
    },
    {
      dictValue: 11,
      dictName: i18n.t('taskManagerInfo.Unequal'),
    },
    {
      dictValue: 9,
      dictName: i18n.t('taskManagerInfo.Is null'),
    },
    {
      dictValue: 10,
      dictName: i18n.t('taskManagerInfo.Is not null'),
    }
  ]
  return list
}

export function getTextSymbolList() {
  const list = [
    {
      dictValue: 1,
      dictName: i18n.t('taskManagerInfo.Equal'),
    },
    {
      dictValue: 6,
      dictName: i18n.t('taskManagerInfo.Contains'),
    },
    {
      dictValue: 7,
      dictName: i18n.t('taskManagerInfo.Is empty'),
    },
    {
      dictValue: 8,
      dictName: i18n.t('taskManagerInfo.Is not empty'),
    },
    {
      dictValue: 9,
      dictName: i18n.t('taskManagerInfo.Is null'),
    },
    {
      dictValue: 10,
      dictName: i18n.t('taskManagerInfo.Is not null'),
    },
  ]
  return list
}

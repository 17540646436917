<template>
  <el-dialog
    v-if="dialogVisible"
    :visible.sync="dialogVisible"
    :before-close="cancel"
    :close-on-click-modal="false"
    append-to-body
    width="1000px"
    class="task-manger-task-history"
    style="padding: 10px"
  >
    <div slot="title">
      <span class="dialog-title">{{$t("taskManagerInfo.Task Update Records")}}</span>
    </div>
    <el-table
      v-if="dialogVisible"
      v-loading="loading"
      :data="tableData"
      style="width: 100%"
      class="table-topstyle"
      :header-cell-style="{ 'text-align': 'center' }"
      :cell-style="{ 'text-align': 'center' }"
    >
      <el-table-column
        :label="$t('taskManagerInfo.No')"
        align="center"
        type="index"
        width="50"
      />
      <el-table-column
        :label="$t('taskManagerInfo.Task Name')"
        align="center"
        prop="taskName"
      />
      <el-table-column
        :label="$t('taskManagerInfo.Modifications')"
        align="center"
        prop="modifucations"
      />
      <el-table-column
        :label="$t('taskManagerInfo.Modified By')"
        align="center"
        prop="createUser"
      />

      <el-table-column
        :label="$t('taskManagerInfo.Modification Time')"
        align="center"
        prop="createTime"
      />
    </el-table>
    <pagination :tableInfo="pageParams" @changePage="changePage" />
  </el-dialog>
</template>

<script>
import { dateFormat } from '@/views/taskManagement/taskManger/utils/dateUtil.js'

import modelUi from '@/views/taskManagement/taskManger/utils/modelUi.js'

import typeUtil from '@/views/taskManagement/taskManger/utils/typeUtil.js'

import { updateHistorySchedule } from '@/libs/api/taskManger.js'

import { parseResp, parseError } from '@/views/taskManagement/taskManger/utils/requestUtil.js'
import pagination from "@/components/pagination";
export default {
  components: {
    pagination
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      taskScheduleId: '',
      tableData: [],
      pageParams: {
        current: 1,
        size: 10,
        total: 10,
      },
    }
  },
  methods: {
    changePage() {
      this.updateHistoryScheduleReq();
    },
    openDialog(taskScheduleId) {
      this.taskScheduleId = taskScheduleId
      this.dialogVisible = true
      this.updateHistoryScheduleReq()
    },
    cancel() {
      this.dialogVisible = false
    },
    updateHistoryScheduleReq() {
      const { taskScheduleId } = this
      const { current, size } = this.pageParams
      const orders = []
      this.loading = true
      updateHistorySchedule(taskScheduleId, current, size, orders)
        .then(res => {
          this.loading = false

          const result = parseResp(res)
          if (result.success) {
            const tableData = result.data.records || []
            tableData.forEach(tableItem => {
              const { createTime } = tableItem
              // eslint-disable-next-line no-param-reassign
              tableItem.createTimeString = ''
              if (createTime) {
                if (typeUtil.isString(createTime)) {
                // eslint-disable-next-line no-param-reassign
                  // tableItem.createTimeString = dateFormat(
                  //   new Date(createTime),
                  //   'yyyy-MM-dd HH:mm:ss',
                  // )
                }
                if (typeUtil.isDate(createTime)) {
                // eslint-disable-next-line no-param-reassign
                  // tableItem.createTimeString = dateFormat(
                  //   createTime,
                  //   'yyyy-MM-dd HH:mm:ss',
                  // )
                }
              }
            })
            this.tableData = tableData
            this.pageParams.current = result.data.current
            this.pageParams.total = result.data.total
          } else {
            modelUi.msgError(result.message)
          }
        })
        .catch(err => {
          this.loading = false
          modelUi.msgError(parseError(err))
        })
    },
  },
}
</script>

<style lang="scss" scope>
.task-manger-task-history {
  .dialog-title {
    font-size: 18px;
    font-family: Verdana;
    font-weight: 400;
    color: #35b871;
  }

  .el-dialog__header {
    background-color: #E9F9EF;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom: #D3F4E1;
  }

  .el-dialog__body {
    padding: 30px 20px !important;
  }
}
</style>

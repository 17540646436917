<template>
  <el-dialog
    v-if="dialogVisible"
    :visible.sync="dialogVisible"
    :before-close="cancel"
    :close-on-click-modal="false"
    append-to-body
    width="820px"
    class="task-manger-step-one-dialog"
    style="padding: 10px"
  >
    <div
      slot="title"
      class="dialog-header"
    >
      <div class="dialog-index">
        1
      </div>
      <span class="dialog-title"> {{ $t('taskManagerInfo.DiologOne.Please input common information') }} </span>
    </div>
    <div v-if="dialogVisible">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="95px"
        label-position="right"
        size="small"
      >
        <el-form-item
          :label="$t('taskManagerInfo.DiologOne.Task name')"
          prop="taskName"
        >
          <el-input
            clearable
            v-model="form.taskName"
            :maxlength="100"
            :placeholder="$t('taskManagerInfo.DiologOne.Input Task Name')"
          />
        </el-form-item>
        <el-form-item
          :label="$t('taskManagerInfo.DiologOne.Task Type')"
          prop="taskType"
        >
          <el-radio-group
            v-model="form.taskType"
            :disabled="operateDiabled"
            @change="handleTaskTypeChange"
          >
            <el-radio
              v-for="item in taskTypeList"
              :key="item.dictValue"
              style="margin-bottom:10px"
              :label="item.dictValue"
            >
              {{ item.dictName }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          :label="$t('taskManagerInfo.DiologOne.Scenario')"
          prop="scenario"
        >
          <el-radio-group
            v-model="form.scenario"
            :disabled="operateDiabled"
            @change="handleScenarioChange"
          >
            <el-radio
              v-for="item in scenarioList"
              :key="item.scenarioId"
              style="margin-bottom:10px"
              :label="item.scenarioId"
            >
              {{ item.translateTextName }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          :label="$t('taskManagerInfo.DiologOne.Run mode')"
          prop="executionMode"
        >
          <el-radio-group
            v-model="form.executionMode"
            :disabled="operateDiabled"
            @change="handleExecutionModeChange"
          >
            <el-radio
              v-for="item in executionModeList"
              :key="item.dictValue"
              :label="item.dictValue"
            >
              {{ item.dictName }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item  :label="$t('taskManagerInfo.DiologOne.Enable')">
          <el-switch v-model="isEnable" />
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer__1"
      >
        <el-button
          class="task-manger-button-next green-button"
          @click="submitForm"
          size="small"
        >
          {{ $t('taskManagerInfo.Next') }}
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import modelUi from '@/views/taskManagement/taskManger/utils/modelUi.js'

import { getScenarioList } from '@/libs/api/taskManger.js'

import {
  parseResp,
  parseError,
} from '@/views/taskManagement/taskManger/utils/requestUtil.js'

export default {
  components: {},
  data() {
    return {
      dialogVisible: false,
      taskTypeList: [
        {
          dictValue: 1207,
          dictName: this.$t("taskManagerInfo.DiologOne.Switch Template"),
        },
        {
          dictValue: 1206,
          dictName: this.$t("taskManagerInfo.DiologOne.Switch Layout"),
        },
        {
          dictValue: 1203,
          dictName: this.$t("taskManagerInfo.DiologOne.LED"),
        },
        {
          dictValue: 1204,
          dictName: this.$t("taskManagerInfo.DiologOne.Switch Page"),
        },
        {
          dictValue: 1205,
          dictName: this.$t("taskManagerInfo.DiologOne.NFC"),
        },
      ],
      scenarioList: [],
      executionModeList: [
        {
          dictValue: 0,
          dictName: this.$t("taskManagerInfo.DiologOne.Direct Execution(Only once)"),
        },
        {
          dictValue: 1,
          dictName: this.$t("taskManagerInfo.DiologOne.By Sechdule"),
        },
        {
          dictValue: 2,
          dictName: this.$t("taskManagerInfo.DiologOne.Data-Driven"),
        },
        // {
        //   dictValue: 3,
        //   dictName: this.$t("taskManagerInfo.DiologOne.Interface Driven"),
        // },
      ],
      isEnable: true,
      form: {},
      rules: {
        taskName: {
          required: true,
          message: this.$t("taskManagerInfo.DiologOne.Input Task Name"),
          trigger: 'blur',
        },
        taskType: {
          required: true,
          message: this.$t("taskManagerInfo.DiologOne.Select a Task Type"),
          trigger: 'change',
        },
        scenario: {
          required: true,
          message: this.$t("taskManagerInfo.DiologOne.Select a Scenario"),
          trigger: 'change',
        },
        executionMode: {
          required: true,
          message: this.$t("taskManagerInfo.DiologOne.Select a Run mode"),
          trigger: 'change',
        },
      },
    }
  },
  computed: {
    operateDiabled() {
      if (this.form && this.form.taskScheduleId) {
        return true
      }
      return false
    },
  },
  methods: {
    openDialog(row) {
      console.log('打开弹窗--stepOneDialog',row)
      this.form = row
      if (this.form.enable) {
        this.isEnable = true
      } else {
        this.isEnable = false
      }
      this.dialogVisible = true

      if (this.scenarioList.length === 0) {
        this.getScenarioListReq()
      }
    },
    cancel() {
      this.dialogVisible = false
    },
    handleTaskTypeChange() {
      this.form.templateType = ''
      this.form.pageNo = 1
      this.form.force = 1
      this.form.templateFiledType = 1
      this.form.templateFiledName = ''
      this.form.layoutType = 0
      this.form.layoutGroupId = ''
      this.form.layoutId = ''
      this.form.layoutName = ''
      this.form.strategyName = ''
      this.form.ledStartTime = null
      this.form.ledCycles = ''
      this.form.ledFlashColor = ''
      this.form.flashInterval = ''
      this.form.flashDuration = ''
      this.form.stayTime = ''
      this.form.switchPages = ''
      this.form.taskTriggerCondition = ''
      this.form.eslType = ''
      this.form.startTime = null
      this.form.endTime = null
      this.form.frequency = ''
      this.form.cycle = ''
    },
    handleScenarioChange() {
      this.form.taskTriggerCondition = null
      this.form.nfcInfo = ''
    },
    handleExecutionModeChange() {
      this.form.taskTriggerCondition = ''
      this.form.eslType = ''
    },

    getScenarioListReq() {
      const merchantId = localStorage.getItem('merchantId') || ''
      const textKey = ''
      const scenarioKey = ''
      const isEffect = ''
      const agentId = ''
      getScenarioList(merchantId, textKey, scenarioKey, isEffect, agentId)
        .then(res => {
          const result = parseResp(res)
          if (result.success) {
            this.scenarioList = result.data || []
          } else {
            modelUi.msgError(result.message)
          }
        })
        .catch(err => {
          modelUi.msgError(parseError(err))
        })
    },

    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.isEnable) {
            this.form.enable = 1
          } else {
            this.form.enable = 0
          }
          this.$emit('stepTwo', this.form)
          this.dialogVisible = false
        }
      })
    },
  },
}
</script>

<style lang="scss" scope>
.task-manger-step-one-dialog {
  .el-radio-group{
    vertical-align: text-top;
  }
  .dialog-header {
    display: flex;
    align-items: center;
  }
  .dialog-index {
    width: 26px;
    height: 26px;
    line-height: 26px;
    font-size: 14px;
    background: linear-gradient(199deg, #29f19c 0%, #14cad5 100%);
    border-radius: 0px 20px 20px 20px;
    text-align: center;
    font-family: Impact;
    font-weight: 400;
    color: #ffffff;
  }

  .dialog-title {
    margin-left: 10px;
    font-size: 16px;
    font-family: Verdana;
    font-weight: 400;
    color: #35b871;
  }

  .el-dialog__header {
    background-color: #E9F9EF;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom: #D3F4E1;
  }

  .el-dialog__body {
    padding: 30px 100px !important;
  }

  // .el-radio-button__inner, .el-radio-group {
  //   display: inline-block;
  //   vertical-align: middle;
  //   font-size: 0;
  // }

  //  .el-radio-group {
  //   display: inline-block;
  //   vertical-align: middle;
  //   font-size: 0;
  // }

  .dialog-footer__1 {
    display: flex;
    justify-content: center;
    margin-top: 50;
  }

  .task-manger-button-next {
    // border: 0px;
    width: 110px;
  }
}
</style>

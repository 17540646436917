<template>
  <el-dialog
    v-if="dialogVisible"
    :visible.sync="dialogVisible"
    :before-close="cancel"
    :close-on-click-modal="false"
    append-to-body
    width="820px"
    class="task-manger-switch-led-two-dialog"
    style="padding: 10px"
  >
    <div
      slot="title"
      class="dialog-header"
    >
      <div class="dialog-index">
        2
      </div>
      <span class="dialog-title">{{ $t("taskManagerInfo.LED Config") }}</span>
    </div>

    <div v-if="dialogVisible">
      <el-form ref="form" size="small" :model="form" :rules="rules" label-width="155px">
        <el-form-item :label="$t('taskManagerInfo.Strategy Name')" prop="strategyName">
          <el-input clearable v-model="form.strategyName" :disabled="true" />
        </el-form-item>

        <el-form-item  :label="$t('taskManagerInfo.Start Time')" prop="ledStartTime">
          <el-input clearable v-model="ledStartTime" :disabled="true" />
        </el-form-item>

        <el-form-item :label="$t('taskManagerInfo.Cycles')"  prop="ledCycles">
          <el-input clearable v-model="form.ledCycles" :disabled="true" />
        </el-form-item>

        <el-form-item  :label="$t('taskManagerInfo.Flash Color')" prop="ledFlashColor">
          <el-select
            v-model="form.ledFlashColor"
            style="width: 100%"
            :disabled="true"
            clearable
          >
            <el-option
              v-for="dict in flashColorList"
              :key="dict.codeValue"
              :label="dict.codeName"
              :value="dict.codeValue"
            />
          </el-select>
        </el-form-item>

        <el-form-item :label="$t('taskManagerInfo.Flash Interva')" prop="flashInterval">
          <el-input clearable v-model="form.flashInterval" :disabled="true" />
        </el-form-item>

        <el-form-item :label="$t('taskManagerInfo.Flash Duration')" prop="flashDuration">
          <el-input clearable v-model="form.flashDuration" :disabled="true" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer__led">
        <el-button size="small" class="button-next gray-button" @click="handlePrior"> {{ $t("taskManagerInfo.Prior") }}</el-button>
        <el-button size="small" class="button-next green-button" @click="handleNext"> {{ $t("taskManagerInfo.Next") }}</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import modelUi from "@/views/taskManagement/taskManger/utils/modelUi.js";

import { dateFormat } from "@/views/taskManagement/taskManger/utils/dateUtil.js";

import { getCodeList } from "@/libs/api/taskManger.js";

import typeUtil from "@/views/taskManagement/taskManger/utils/typeUtil.js";

import { parseResp, parseError } from '@/views/taskManagement/taskManger/utils/requestUtil.js'


export default {
  components: {},
  data() {
    return {
      dialogVisible: false,
      ledStartTime: "",
      srcForm: {},
      form: {},
      flashColorList: [],
      rules: {
        strategyName: {
          required: true,
          message: this.$t("taskManagerInfo.Input Strategy Name"),
          trigger: "blur",
        },
        ledStartTime: {
          required: true,
          message: this.$t("taskManagerInfo.Input Start Time"),
          trigger: "blur",
        },
        ledCycles: { required: true, message: this.$t("taskManagerInfo.Input Cycles"), trigger: "blur" },
        ledFlashColor: {
          required: true,
          message: this.$t("taskManagerInfo.Select a Flash Color"),
          trigger: "change",
        },
        flashInterval: {
          required: true,
          message: this.$t("taskManagerInfo.Input Flash Interva"),
          trigger: "blur",
        },
        flashDuration: {
          required: true,
          message: this.$t("taskManagerInfo.Input Flash Duration"),
          trigger: "blur",
        },
      },
    };
  },
  methods: {
    openDialog(row) {
      console.log('闪灯配置第二步',row)
      this.srcForm = JSON.parse(JSON.stringify(row));
      this.form = row;
      if (this.form.ledStartTime) {
        if (typeUtil.isString(this.form.ledStartTime)) {
          this.ledStartTime = dateFormat(
            new Date(this.form.ledStartTime),
            "yyyy-MM-dd HH:mm:ss"
          );
        }
        if (typeUtil.isDate(this.form.ledStartTime)) {
          this.ledStartTime = dateFormat(this.form.ledStartTime, "yyyy-MM-dd HH:mm:ss");
        }
      } else {
        this.ledStartTime = null;
      }
      this.dialogVisible = true;
      this.getCodeListReq();
    },

    cancel() {
      this.dialogVisible = false;
    },

    getCodeListReq() {
      getCodeList(28)
        .then((res) => {
           const result = parseResp(res)
          if (result.success) {
            this.flashColorList = result.data
          } else {
            modelUi.msgError(result.message)
          }
        })
        .catch((err) => {
          modelUi.msgError(parseError(err))
        });
    },

    handlePrior() {
      this.$emit("ledOne", this.srcForm);
      this.dialogVisible = false;
    },

    handleNext() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit("stepThree", this.form);
          this.dialogVisible = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scope>
.task-manger-switch-led-two-dialog {
  .dialog-header {
    display: flex;
    align-items: center;
  }
  .dialog-index {
    width: 26px;
    height: 26px;
    line-height: 26px;
    font-size: 14px;
    background: linear-gradient(199deg, #29f19c 0%, #14cad5 100%);
    border-radius: 0px 20px 20px 20px;
    text-align: center;
    font-family: Impact;
    font-weight: 400;
    color: #ffffff;
  }

  .dialog-title {
    margin-left: 10px;
    font-size: 16px;
    font-family: Verdana;
    font-weight: 400;
    color: #35b871;
  }

  .el-dialog__header {
    background-color: #E9F9EF;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom: #D3F4E1;
  }

  .el-dialog__body {
    padding: 30px 20px !important;
  }

  .dialog-footer__led {
    display: flex;
    justify-content: center;
  }

  .button-next {
    // border: 0px;
    width: 110px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
</style>
